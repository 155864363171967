import React from 'react';
import { Stack, Box, Button, IconButton, Typography } from '@mui/material';
import { SmartLink } from '../../abelcommon';
import { SmartNode } from '../components';
import { isMiniBar, useConfig } from '../config';
import { useHeaderContext } from './context';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

export const HeaderBannerLogo = () => {
  const isTestnet = window.location.href.includes('testnet');
  const c = useConfig();
  const miniBar = isMiniBar(c);
  return (
    <Button
      component={SmartLink}
      to={'/'}
      disableRipple
      sx={{
        textTransform: 'none',
        ':hover': { backgroundColor: 'inherit' },
        p: 0,
        mt: miniBar ? 0 : { xs: 0, sm: 1 },
        mb: miniBar ? 0 : { xs: 0, sm: -1 },
      }}
    >
      <SmartNode
        value={c.header.banner.title}
        typographyProps={{ variant: 'h1' }}
      />
      {isTestnet && (
        <Typography variant={'h2'} color={'warning.main'} sx={{ ml: 1 }}>
          (Testnet)
        </Typography>
      )}
    </Button>
  );
};

export const HeaderBannerButton = () => {
  const { s, m } = useHeaderContext();
  const sxTextHover = {
    color: 'text.secondary',
    ':hover': { color: 'text.primary' },
  };
  return (
    <IconButton
      sx={{
        display: { xs: 'flex', sm: 'none' },
        backgroundColor: 'inherit',
        ':hover': {
          backgroundColor: 'inherit',
        },
        mr: -1,
      }}
      onClick={() => m({ drawerOpen: !s.drawerOpen })}
    >
      {s.drawerOpen ? (
        <CloseIcon sx={sxTextHover} />
      ) : (
        <MenuIcon sx={sxTextHover} />
      )}
    </IconButton>
  );
};

export const HeaderBanner = () => {
  const { c } = useHeaderContext();
  const miniBar = isMiniBar(c);
  return (
    <Stack id={'header-banner'}>
      <Box
        id={'header-banner-title'}
        sx={{ display: 'flex', height: c.header.barHeight.mini, mx: 2 }}
      >
        <Stack
          direction={'row'}
          sx={{
            width: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <HeaderBannerLogo />
          <HeaderBannerButton />
        </Stack>
      </Box>
      <Box
        id={'header-banner-subtitle'}
        sx={{
          display: miniBar ? 'none' : { xs: 'none', sm: 'flex' },
          alignItems: 'center',
          height: c.header.barHeight.mini,
          mx: 2,
        }}
      >
        <SmartNode
          value={c.header.banner.subtitle}
          typographyProps={{ variant: 'h3' }}
        />
      </Box>
    </Stack>
  );
};

export default HeaderBanner;
