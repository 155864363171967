import React from 'react';
import { Avatar, Typography } from '@mui/material';

export const TextLoading = <Typography variant="body1">Loading...</Typography>;
export const TextRequestFailed = (
  <Typography variant="body1">Request failed.</Typography>
);

export type TextAvatarProps = {
  text?: string;
  square?: boolean;
  size?: number;
  textSize?: number;
  borderRadius?: string;
};

export const TextAvatar = ({
  text = '',
  size = 40,
  textSize = 14,
  square = false,
}: TextAvatarProps) => {
  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: textSize,
        borderRadius: square ? '10%' : '50%',
      }}
    >
      {text}
    </Avatar>
  );
};
