import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UseQueryResult } from 'react-query';
import {
  SxProps,
  Button,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import MainCard from './MainCard';
import ErrorIcon from '@mui/icons-material/Error';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { SmartLink } from '../abelcommon';
import CooldownButton from './CooldownButton';
import JSONTextField from './JSONTextField';

export type ErrorCardProps = {
  children?: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
  reloadOnGoHome?: boolean;
  enableGoHome?: boolean;
  enableGoBack?: boolean;
  enableRetry?: boolean;
  onRetry?: () => void;
  sx?: SxProps;
};

export const ErrorCard = ({
  children,
  title = 'An error has occurred.',
  icon = <ErrorIcon fontSize={'small'} color={'warning'} />,
  reloadOnGoHome = false,
  enableGoHome = true,
  enableGoBack = false,
  enableRetry = false,
  onRetry = () => window.location.reload(),
  sx = {},
}: ErrorCardProps) => {
  const navigate = useNavigate();
  return (
    <MainCard
      avatar={icon}
      title={title}
      sx={{ border: 1, borderWidth: 2, borderColor: 'warning.main', ...sx }}
    >
      <CardContent>
        {children || 'Sorry! We have just encountered an error.'}
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}>
        {enableGoHome && (
          <Button
            variant={enableRetry || enableGoBack ? 'outlined' : 'contained'}
            component={SmartLink}
            to={'/'}
            target={'_self'}
            forceExternal={reloadOnGoHome}
            color={'warning'}
          >
            Go Home
          </Button>
        )}
        {enableGoBack && (
          <Button
            variant={enableRetry ? 'outlined' : 'contained'}
            component={SmartLink}
            color={'warning'}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        )}
        {enableRetry && (
          <CooldownButton
            millis={[3000, 5000]}
            variant={'contained'}
            component={SmartLink}
            onClick={onRetry}
            color={'warning'}
          >
            Retry
          </CooldownButton>
        )}
      </CardActions>
    </MainCard>
  );
};

export const UnexpectedErrorCard = () => {
  return (
    <ErrorCard reloadOnGoHome={true}>
      Sorry! We have just encountered an unexpected error.
    </ErrorCard>
  );
};

export const PageUnderConstructionCard = ({ sx }: { sx?: SxProps }) => {
  return (
    <ErrorCard
      icon={<NotificationsIcon fontSize={'small'} color={'warning'} />}
      title={'Notification'}
      enableGoBack={true}
      sx={sx}
    >
      This page is under construction.
    </ErrorCard>
  );
};

export const ModuleUnderConstructionCard = ({ sx }: { sx?: SxProps }) => {
  return (
    <ErrorCard
      icon={<NotificationsIcon fontSize={'small'} color={'warning'} />}
      title={'Notification'}
      enableGoHome={false}
      sx={sx}
    >
      This module is under construction.
    </ErrorCard>
  );
};

export const PageNotFoundCard = () => {
  return (
    <ErrorCard title={'HTTP 404'} enableGoBack={true}>
      Page not found.
    </ErrorCard>
  );
};

export type QueryErrorCardProps = {
  query?: UseQueryResult<any, any>;
};

export const QueryFailedCard = ({ query }: QueryErrorCardProps) => {
  const onRetry = query
    ? () => query.refetch()
    : () => window.location.reload();
  return (
    <ErrorCard enableGoBack={true} enableRetry={true} onRetry={onRetry}>
      <Typography variant={'h4'}>Failed fetching data from server.</Typography>
      <JSONTextField value={query?.error} sx={{ mt: 2 }} />
    </ErrorCard>
  );
};

export const QueryResponseErrorCard = ({ query }: QueryErrorCardProps) => {
  const onRetry = query
    ? () => query.refetch()
    : () => window.location.reload();
  return (
    <ErrorCard enableGoBack={true} enableRetry={true} onRetry={onRetry}>
      <Typography variant={'h4'}>Unexpected server response:</Typography>
      <JSONTextField value={query?.data} sx={{ mt: 2 }} />
    </ErrorCard>
  );
};

export default ErrorCard;
