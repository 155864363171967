import React from 'react';
import { UseQueryResult } from 'react-query';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import JSONTextField from './JSONTextField';
import ErrorCard from './ErrorCard';

export type QueryProcessorProps = {
  query: UseQueryResult<any, any>;
  dataValidator?: (data: any) => boolean;
  renderer?: (data: any) => React.ReactNode | null;
};

export const QueryProcessor: any = ({
  query,
  dataValidator = () => true,
  renderer = () => null,
}: QueryProcessorProps) => {
  if (query?.isLoading || query.isRefetching) {
    return <LinearProgress color={'warning'} />;
  }

  let message: string = '';
  let details: string = '';
  if (query?.isError || !query?.isSuccess) {
    message = 'Query failed with message:';
    details = query?.error?.message ?? 'Unknown reason';
  } else if (query?.isSuccess && !dataValidator(query.data)) {
    message = 'Query succeeded with an invalid result:';
    details = query.data;
  }

  if (!message) {
    return renderer(query.data);
  }

  const onRetry = () => (query ? query.refetch() : window.location.reload());
  return (
    <ErrorCard enableGoBack={true} enableRetry={true} onRetry={onRetry}>
      <Typography variant={'h4'}>{message}</Typography>
      <JSONTextField value={details} sx={{ mt: 2 }} />
    </ErrorCard>
  );
};

export default QueryProcessor;
