import axios from 'axios';
import { useQuery } from 'react-query';
import { environment } from '../config/environment';

export function fetchNetwork() {
  return axios.get(`${environment.apiBase}/network`).then((res) => res.data);
}

export function useNetwork() {
  return useQuery('network', () => fetchNetwork(), {
    retry: false,
    staleTime: 300 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export function fetchNetworkSeeds() {
  return axios.get(`${environment.apiBase}/network?active_in_dnsseed=true`).then((res) => res.data);
}

export function useNetworkSeeds() {
  return useQuery('network-seeds', () => fetchNetworkSeeds(), {
    retry: false,
    staleTime: 300 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export default useNetwork;