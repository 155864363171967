import React from 'react';
import MuiExpandMore from '@mui/icons-material/ExpandMore';
import MuiExpandLess from '@mui/icons-material/ExpandLess';
import SmartNode from './SmartNode';
import { FaDiscord as DiscordIcon, FaMediumM as MediumIcon } from 'react-icons/fa';
import { Theme } from '@mui/material';

export const ExpandMore = () => {
  return (
    <MuiExpandMore sx={{ color: 'text.secondary', fontSize: 14, ml: 0.5 }} />
  );
};

export const ExpandLess = () => {
  return (
    <MuiExpandLess sx={{ color: 'text.secondary', fontSize: 14, ml: 0.5 }} />
  );
};

export type ReactNodeMaker = (theme: Theme) => React.ReactNode;

export { DiscordIcon, MediumIcon, SmartNode };
