import React from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import { AppSearchBox } from '../../components/SearchBox';

const SearchBar = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 1,
        height: 160,
        mb: 1,
        color: 'white',
      }}
    >
      <Typography variant="h1" sx={{ mb: 2 }}>
        Abelian Blockchain Explorer
      </Typography>
      {theme.palette.mode === 'dark' && (
        <AppSearchBox sx={{ width: { xs: 1, sm: 480 } }} />
      )}
      {theme.palette.mode === 'light' && (
        <AppSearchBox
          sx={{ width: { xs: 1, sm: 480 } }}
          sxInput={{ color: 'lightgray', '& :hover': { color: 'white' } }}
        />
      )}
    </Box>
  );
};

export default SearchBar;
