import React from 'react';
import {
  List,
  ListItemButton,
  ListItem,
  Collapse,
  Divider,
} from '@mui/material';
import { SmartLink } from '../../abelcommon';
import { SmartNode, ExpandMore, ExpandLess } from '../components';
import { NavItem } from '../config';
import { useHeaderContext } from './context';

type HeaderDrawerRegularItemProps = {
  d: NavItem;
  level: 0 | 1;
};

const HeaderDrawerRegularItem = ({
  d,
  level,
}: HeaderDrawerRegularItemProps) => {
  const { s, m } = useHeaderContext();
  if (d.to) {
    return (
      <ListItemButton
        component={SmartLink}
        to={d.to}
        target={d.target}
        disableGutters
        sx={{ width: 1, px: level * 2 }}
        disabled={d.disabled}
      >
        <SmartNode
          value={d.title}
          typographyProps={{
            variant: level === 0 ? 'h4' : 'h5',
            color: 'textSecondary',
          }}
        />
      </ListItemButton>
    );
  } else if (d.content === 'divider') {
    return <Divider />;
  }

  return (
    <>
      <ListItem
        secondaryAction={
          s.drawerExpanded === d.title ? <ExpandLess /> : <ExpandMore />
        }
        onClick={() =>
          m({ drawerExpanded: s.drawerExpanded === d.title ? null : d.title })
        }
        disableGutters
        sx={{ px: 0, width: 1 }}
      >
        <SmartNode
          value={d.title}
          typographyProps={{ variant: 'h4', color: 'textSecondary' }}
        />
      </ListItem>
      <Collapse
        in={s.drawerExpanded === d.title}
        sx={{ borderLeft: 1, borderWidth: 3, borderColor: 'primary.main' }}
      >
        <List sx={{ py: 0 }}>
          {Array.isArray(d.content) &&
            d.content.map((dd, i) => {
              const key = d.title + '-' + i;
              if (dd.content === 'divider') {
                return <Divider key={key} sx={{ mx: 2 }} />;
              } else {
                return <HeaderDrawerRegularItem key={key} d={dd} level={1} />;
              }
            })}
        </List>
      </Collapse>
    </>
  );
};

export const HeaderDrawer = () => {
  const { c } = useHeaderContext();
  return (
    <List sx={{ mx: 2, mb: 2, p: 0, width: 1 }}>
      {c.nav.regular.map((item, i) => (
        <HeaderDrawerRegularItem key={i} level={0} d={item} />
      ))}
    </List>
  );
};

export default HeaderDrawer;
