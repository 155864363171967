import React, { ReactElement } from 'react';
import {
  SxProps,
  Card,
  CardHeader,
  Divider,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { KeyedObject } from '../abelcommon';

export type MainCardProps = KeyedObject & {
  avatar?: React.ReactNode;
  title?: string;
  sx?: SxProps;
};

export const MainCard = React.forwardRef(
  (
    { avatar, title, sx, ...others }: MainCardProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <Card ref={ref} {...others} sx={{ px: 0, ...sx }}>
        {(avatar || title) && (
          <CardHeader
            avatar={avatar}
            sx={{ '& .MuiCardHeader-action': { mr: 0 } }}
            title={<Typography variant="h3">{title}</Typography>}
          />
        )}
        {(avatar || title) && <Divider />}
        {others['children']}
      </Card>
    );
  }
);

export type TabbedMainCardProps = KeyedObject & {
  children: ReactElement[];
  labels: string[];
  initialTab?: number;
};

export const TabbedMainCard = React.forwardRef(
  (
    { children, labels, defaultTab = 0, ...others }: TabbedMainCardProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [tab, setTab] = React.useState(defaultTab);
    const title = (
      <Tabs
        value={tab}
        sx={{ m: -2, '& .MuiTab-root': { textTransform: 'none' } }}
      >
        {labels.map((label, i) => (
          <Tab key={i} label={label} onClick={() => setTab(i)} />
        ))}
      </Tabs>
    );
    return (
      <MainCard ref={ref} {...others} title={title}>
        {labels.length === 1 && children}
        {labels.length > 1 && children[tab]}
      </MainCard>
    );
  }
);

export default MainCard;
