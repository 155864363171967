import React from 'react';
import { Grid, Typography, CardContent } from '@mui/material';
import LocalAuthGuard from '../../components/LocalAuthGuard';
import { TabbedMainCard } from '../../components/MainCard';
import { ActiveMiners, ActiveSeeds, NetworkStatistics } from './network';
import { CoinbaseStatistics } from './coinbase';

const tabs = [
  { label: 'Discovered Mining Sites', element: <ActiveMiners /> },
  { label: 'Active Seed Nodes', element: <ActiveSeeds /> },
  { label: 'Network Statistics', element: <NetworkStatistics /> },
  { label: 'Coinbase Statistics', element: <CoinbaseStatistics />},
];

export function AdminDashboard() {
  return (
    <LocalAuthGuard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h2'}>Admin Dashboard</Typography>
        </Grid>
        <Grid item xs={12}>
          <TabbedMainCard labels={tabs.map((v) => v.label)}>
            {tabs.map((v) => (
              <CardContent>{v.element}</CardContent>
            ))}
          </TabbedMainCard>
        </Grid>
      </Grid>
    </LocalAuthGuard>
  );
}

export default AdminDashboard;
