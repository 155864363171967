import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useCoinbase } from '../../queries/useCoinbase';
import QueryProcessor from '../../components/QueryProcessor';

function statAddress(data: any) {
  const stat: any = {};
  for (let i = 0; i < data.length; i++) {
    const [, hash, prefix, suffix] = data[i];
    if (!(hash in stat)) {
      stat[hash] = {
        hash: hash,
        fingerprint: `0x${prefix}..${suffix}`,
        nAll: 0,
        nHist: 0,
        nLast10k: 0,
        nLast3k: 0,
        nLast1k: 0,
        nLast300: 0,
        nLast100: 0,
        nLast30: 0,
        nLast10: 0,
      };
    }
    stat[hash].nAll += 1;
    if (i + 10000 < data.length) {
      stat[hash].nHist += 1;
    }
    if (i + 10000 >= data.length) {
      stat[hash].nLast10k += 1;
    }
    if (i + 3000 >= data.length) {
      stat[hash].nLast3k += 1;
    }
    if (i + 1000 >= data.length) {
      stat[hash].nLast1k += 1;
    }
    if (i + 300 >= data.length) {
      stat[hash].nLast300 += 1;
    }
    if (i + 100 >= data.length) {
      stat[hash].nLast100 += 1;
    }
    if (i + 30 >= data.length) {
      stat[hash].nLast30 += 1;
    }
    if (i + 10 >= data.length) {
      stat[hash].nLast10 += 1;
    }
  }
  return stat;
}

const columns: GridColDef[] = [
  { field: 'fingerprint', headerName: 'Address', flex: 1, minWidth: 200 },
  { field: 'nLast10', headerName: 'L10', flex: 1, minWidth: 50, headerAlign: 'right', align: 'right' },
  { field: 'nLast30', headerName: 'L30', flex: 1, minWidth: 50, headerAlign: 'right', align: 'right' },
  { field: 'nLast100', headerName: 'L100', flex: 1, minWidth: 50, headerAlign: 'right', align: 'right' },
  { field: 'nLast300', headerName: 'L300', flex: 1, minWidth: 50, headerAlign: 'right', align: 'right' },
  { field: 'nLast1k', headerName: 'L1000', flex: 1, minWidth: 50, headerAlign: 'right', align: 'right' },
  { field: 'nLast3k', headerName: 'L3000', flex: 1, minWidth: 50, headerAlign: 'right', align: 'right' },
  { field: 'nLast10k', headerName: 'L10000', flex: 1, minWidth: 50, headerAlign: 'right', align: 'right' },
  { field: 'nHist', headerName: 'Hist', flex: 1, minWidth: 100, headerAlign: 'right', align: 'right' },
  { field: 'nAll', headerName: 'All', flex: 1, minWidth: 100, headerAlign: 'right', align: 'right' },
];

export function CoinbaseStatistics() {
  const coinbaseQuery = useCoinbase();
  return (
    <QueryProcessor
      query={coinbaseQuery}
      dataValidator={(data: any) => data.type === 'coinbase'}
      renderer={(data: any) => {
        const stat = Object.entries(statAddress(data.payload))
          .map((e: any) => e[1])
          .sort((a, b) => b.nLast100 - a.nLast100);
        return (
          <DataGrid
            autoHeight
            rows={stat}
            columns={columns}
            getRowId={(d) => d.fingerprint}
            pageSizeOptions={[100]}
            sx={{ fontFamily: 'Monospace' }}
          />
        );
      }}
    />
  );
}
