import {
  Theme,
  Box,
  FormControlLabel,
  Switch,
  Typography,
  List,
  ListItemButton,
  Divider,
} from "@mui/material";
import { SmartLink } from "../abelcommon";
import { LayoutConfig } from "../layout/config";
import { useRecoilState } from "recoil";
import { paletteModeState } from "./theme";
import { AppSearchBox } from "../components/SearchBox";
import { UnexpectedErrorCard } from "../components/ErrorCard";

const makeLogo = (theme: Theme) => {
  return theme.palette.mode === "light" ? (
    <Box
      component={"img"}
      src={"/abel-logo-black.png"}
      sx={{ height: 36, width: 215 }}
    />
  ) : (
    <Box
      component={"img"}
      src={"/abel-logo-white.png"}
      sx={{ height: 36, width: 215 }}
    />
  );
};

const makeSettingsIcon = (theme: Theme) => {
  return theme.palette.mode === "light" ? (
    <Box
      component={"img"}
      src={"/abel-icon-grayscale.png"}
      sx={{ height: 20, width: 20 }}
    />
  ) : (
    <Box
      component={"img"}
      src={"/abel-icon-white.png"}
      sx={{ height: 20, width: 20 }}
    />
  );
};

const HeaderSettingsContent = (popupState: any) => {
  const isTestnet = window.location.href.includes("testnet");
  return (
    <List>
      <ListItemButton
        key={1}
        component={SmartLink}
        to={"https://explorer.abelian.info"}
        onClick={() => {
          popupState.setOpen(false);
        }}
        target={"_self"}
      >
        <Typography
          variant={"h6"}
          color={"textSecondary"}
          sx={{ fontWeight: isTestnet ? "normal" : "bold" }}
        >
          Abelian Mainnet
        </Typography>
      </ListItemButton>
      <Divider />
      <ListItemButton
        key={2}
        component={SmartLink}
        to={"https://testnet-explorer.abelian.info"}
        onClick={() => {
          popupState.setOpen(false);
        }}
        target={"_self"}
      >
        <Typography
          variant={"h6"}
          color={"textSecondary"}
          sx={{ fontWeight: isTestnet ? "bold" : "normal" }}
        >
          Abelian Testnet
        </Typography>
      </ListItemButton>
    </List>
  );
};

const footerTopbarIcon = (
  <Box
    component={"img"}
    src={"/abel-icon-blue.png"}
    sx={{ height: 20, width: 20 }}
  />
);

const FooterBannerActions = () => {
  const [paletteMode, setPaletteMode] = useRecoilState(paletteModeState);
  const onSwitchMode = (e: any) => {
    setPaletteMode(e.target.checked ? "dark" : "light");
  };
  return (
    <FormControlLabel
      control={
        <Switch
          color={"primary"}
          checked={paletteMode === "dark"}
          onChange={onSwitchMode}
        />
      }
      label={<Typography variant={"body2"}>Dark Mode</Typography>}
    />
  );
};

export const layoutConfig: LayoutConfig = {
  maxWidth: "xl",
  nav: {
    regular: [
      { title: "Home", to: "/" },
      { title: null, content: "divider" },
      {
        title: "Blockchain",
        content: [
          { title: "View Blocks", to: "/blocks" },
          { title: null, content: "divider" },
          { title: "View Transactions", to: "/txs" },
          { title: "View Transfers", to: "/txs?type=transfer" },
          { title: null, content: "divider" },
          { title: "View Addresses", to: "/addresses" },
        ],
      },
      {
        title: "Resources",
        content: [
          { title: "Charts", to: "/charts", disabled: true },
          { title: "Statistics", to: "/statistics", disabled: true },
          { title: null, content: "divider", disabled: true },
          { title: "Downloads", to: "/downloads", disabled: true },
          { title: "Developer APIs", to: "/developer-apis", disabled: true },
        ],
      },
    ],
    more: undefined,
    settings: {
      icon: makeSettingsIcon,
      render: HeaderSettingsContent,
    },
  },
  header: {
    barHeight: { full: "96px", mini: "" },
    miniBarLinks: ["/", "/home"],
    banner: {
      title: makeLogo,
      subtitle: (
        <Typography variant={"h4"} color={"textSecondary"}>
          Abelian Blockchain Explorer (AbelScan)
        </Typography>
      ),
    },
    searchBox: <AppSearchBox sx={{ width: { xs: 1, sm: 480 } }} />,
  },
  footer: {
    barHeight: { full: "", mini: "48px" },
    topbar: {
      icon: footerTopbarIcon,
      title: (
        <Typography variant={"h5"} sx={{ ml: 1 }}>
          Powered by{" "}
          <SmartLink
            to={"https://abelian.info"}
            sx={{ textDecoration: "none" }}
          >
            Abelian
          </SmartLink>
        </Typography>
      ),
      subtitle: () => "AbelScan © " + new Date().getFullYear(),
    },
    sitemap: {
      regular: [
        {
          title: "Blockchain",
          content: [
            { title: "Blocks", to: "/blocks" },
            { title: "Transactions", to: "/txs" },
            { title: "Transfers", to: "/txs?type=transfer" },
            { title: "Addresses", to: "/addresses" },
          ],
        },
        {
          title: "Resources",
          content: [
            { title: "Charts", to: "/charts", disabled: true },
            { title: "Statistics", to: "/statistics", disabled: true },
            { title: "Downloads", to: "/downloads", disabled: true },
            { title: "Developer APIs", to: "https://docs.abelian.info" },
          ],
        },
        {
          title: "Links",
          content: [
            { title: "Abelian Foundation", to: "https://abelian.info" },
            { title: "Mainnet", to: "https://explorer.abelian.info" },
            {
              title: "Testnet",
              to: "https://testnet-explorer.abelian.info",
            },
          ],
        },
      ],
      community: {
        links: [
          { icon: "Twitter", to: "https://twitter.com/PQabelian" },
          { icon: "Discord", to: "https://discord.gg/5rrDxP29hx" },
          { icon: "Telegram", to: "https://t.me/abelianofficial" },
          { icon: "Medium", to: "https://pqabelian.medium.com" },
        ],
      },
    },
    banner: {
      icon: undefined,
      title: undefined,
      text: (
        <Typography variant={"body2"} color={"textSecondary"}>
          AbelScan is a blockchain explorer and analytics platform for Abelian,
          a quantum-resistant privacy-enhancing Blockchain infrastructure, which
          enables digital gold 2.0 and empowers the post-quantum crypto
          ecosystem.
        </Typography>
      ),
      actions: <FooterBannerActions />,
    },
  },
  main: {
    sxWrapperForHome: (theme: Theme) => {
      const sxLight = {
        background:
          "linear-gradient(180deg, #333 0%, #333 300px, #ddd 300px, #ddd 100%);",
      };
      const sxDark = {
        background:
          "linear-gradient(180deg, #000 0%, #000 300px, #333 300px, #333 100%);",
      };
      return theme.palette.mode === "light" ? sxLight : sxDark;
    },
    errorFallback: UnexpectedErrorCard,
  },
};

export default layoutConfig;
