import React from 'react';
import { Paper, Container, Grid, Stack } from '@mui/material';
import { useConfig } from '../config';
import { HeaderContextProvider, HeaderContextConsumer } from './context';
import HeaderBanner from './HeaderBanner';
import HeaderDrawer from './HeaderDrawer';
import HeaderSearch from './HeaderSearch';
import HeaderNavbar from './HeaderNavbar';

export const Header = () => {
  const c = useConfig();
  return (
    <HeaderContextProvider>
      <Paper component={'header'} sx={{ width: 1, borderRadius: 0 }}>
        <Container maxWidth={c.maxWidth} disableGutters>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <HeaderBanner />
            </Grid>
            <HeaderContextConsumer>
              {({ s }) => (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: s.drawerOpen ? 'flex' : 'none', sm: 'none' },
                  }}
                >
                  <HeaderDrawer />
                </Grid>
              )}
            </HeaderContextConsumer>
            <Grid item xs={12} sm={8}>
              <Stack sx={{ height: 1, px: 2 }}>
                <HeaderSearch />
                <HeaderNavbar />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </HeaderContextProvider>
  );
};

export default Header;
