import React from 'react';
import {
  useTheme,
  Stack,
  Divider,
  Box,
  Container,
  SxProps,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { SmartLink } from '../abelcommon';
import { useConfig } from './config';

const UnexpectedError = () => {
  return (
    <Stack sx={{ mt: 2, width: 1, alignItems: 'center' }}>
      <Typography variant={'h1'}>Unexpected Error</Typography>
      <Divider sx={{ width: 1, my: 2 }} />
      <Typography gutterBottom>
        Sorry! We have just encountered an unexpected error.
      </Typography>
      <SmartLink to={'/'}>Go Home</SmartLink>
    </Stack>
  );
};

export const Main = ({ children }: { children: React.ReactNode }) => {
  const c = useConfig();
  const theme = useTheme();

  let sxWrapper: SxProps = { width: 1 };
  const isHome = ['/', '/home'].includes(useLocation().pathname);
  if (isHome) {
    if (typeof c.main?.sxWrapperForHome === 'function') {
      sxWrapper = { ...sxWrapper, ...c.main.sxWrapperForHome(theme) };
    } else if (c.main?.sxWrapperForHome) {
      sxWrapper = { ...sxWrapper, ...c.main.sxWrapperForHome };
    }
  }

  return (
    <Box id={'wrapper-main'} sx={sxWrapper}>
      <Container component="main" maxWidth={c.maxWidth} disableGutters>
        <Box sx={{ mt: 2, mb: 6, px: 2, width: 1, minHeight: 400 }}>
          <ErrorBoundary
            FallbackComponent={c?.main?.errorFallback ?? UnexpectedError}
          >
            {children}
          </ErrorBoundary>
        </Box>
      </Container>
    </Box>
  );
};

export default Main;
