import React from 'react';
import { Stack } from '@mui/material';
import { useConfig } from '../config';
import { SmartNode } from '../components';

export const FooterTopbar = () => {
  const c = useConfig();
  return (
    <Stack
      id={'footer-banner'}
      direction={'row'}
      sx={{ width: 1, height: c.footer.barHeight.mini, alignItems: 'center' }}
    >
      <SmartNode
        value={c.footer.topbar.icon}
        component={Stack}
        sx={{ ml: 2 }}
      />
      <SmartNode
        value={c.footer.topbar.title}
        typographyProps={{ variant: 'h5', sx: { ml: 1 } }}
      />
      <SmartNode
        value={c.footer.topbar.subtitle}
        typographyProps={{ variant: 'h6', sx: { ml: 'auto', pr: 2 } }}
      />
    </Stack>
  );
};

export default FooterTopbar;
