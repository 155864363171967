import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Typography,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Stack,
  FormControl,
  Divider,
  Select,
  MenuItem,
  Input,
} from '@mui/material';
import MainCard from './MainCard';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function MiniButton(props: ButtonProps) {
  return (
    <Button
      variant={'outlined'}
      {...props}
      sx={{
        minHeight: 24,
        minWidth: 24,
        py: 0,
        px: 1,
        fontSize: 12,
        ...props.sx,
      }}
    />
  );
}

export interface PaginationCardProps {
  title?: string;
  totalItems: number;
  pageSize: number;
  pageNo: number;
  allowedPageSizes?: number[];
  children?: React.ReactElement | React.ReactElement[];
}

export function PaginationCard({
  title,
  totalItems,
  pageSize,
  pageNo,
  allowedPageSizes = [10, 25, 50, 100],
  children,
}: PaginationCardProps) {
  const [inputPageSize, setInputPageSize] = React.useState(pageSize);
  const [inputPageNo, setInputPageNo] = React.useState('');
  const lastPageNo = Math.ceil(totalItems / pageSize);
  const isFirstPage = pageNo === 1;
  const isLastPage = pageNo === lastPageNo;

  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams.toString());

  const JumpToPage = (targetPageSize?: number, targetPageNo?: number) => {
    const jumpToPageSize = targetPageSize ?? inputPageSize;
    const jumpToPageNo = targetPageNo ?? Number(inputPageNo);
    setInputPageNo('');
    if (jumpToPageSize !== pageSize) {
      newSearchParams.set('ps', String(jumpToPageSize));
      newSearchParams.set('p', '1');
    } else if (!isNaN(jumpToPageNo) && jumpToPageNo !== pageNo && jumpToPageNo > 0 && jumpToPageNo <= lastPageNo) {
      newSearchParams.set('ps', String(jumpToPageSize));
      newSearchParams.set('p', String(jumpToPageNo));
    }
    if (newSearchParams.has('pa')) {
      newSearchParams.delete('pa');
      newSearchParams.set('pa', searchParams.get('pa')!);
    }
    return setSearchParams(newSearchParams);
  };

  if (pageNo < 1 || pageNo > lastPageNo) {
    throw new Error(`Unexpected page number: ${pageNo}.`);
  }

  return (
    <MainCard>
      <CardHeader
        sx={{ '& .MuiCardHeader-action': { py: 0, mr: 0 } }}
        title={<Typography variant={'h3'}>{title}</Typography>}
        action={
          <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            <MiniButton disabled={isFirstPage} onClick={() => JumpToPage(pageSize, 1)}>
              First
            </MiniButton>
            <MiniButton sx={{ px: 0 }} disabled={isFirstPage} onClick={() => JumpToPage(pageSize, pageNo - 1)}>
              <ArrowBackIosNewIcon sx={{ fontSize: 10 }} />
            </MiniButton>
            <Typography variant={'h5'} color={'textSecondary'}>
              Page {pageNo} of {lastPageNo}
            </Typography>
            <MiniButton sx={{ px: 0 }} disabled={isLastPage} onClick={() => JumpToPage(pageSize, pageNo + 1)}>
              <ArrowForwardIosIcon sx={{ fontSize: 10 }} />
            </MiniButton>
            <MiniButton disabled={isLastPage} onClick={() => JumpToPage(pageSize, lastPageNo)}>
              Last
            </MiniButton>
          </Stack>
        }
      />
      <Divider />
      <CardContent sx={{ overflowX: 'scroll', mx: 2, px: 0 }}>{children}</CardContent>
      <Divider />
      <CardActions sx={{ mx: 1, my: 0.5 }}>
        <Grid container sx={{ width: 1 }}>
          <Grid item xs={6}>
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
              <Typography variant={'h5'} color={'textSecondary'}>
                Show
              </Typography>
              <FormControl size={'small'} sx={{ mx: 1 }}>
                <Select
                  value={inputPageSize}
                  onChange={(e) => {
                    setInputPageSize(Number(e.target.value));
                    JumpToPage(Number(e.target.value), 1);
                  }}
                  autoWidth
                  sx={{
                    fontSize: 12,
                    '& .MuiSelect-outlined': { py: 0.5 },
                  }}
                >
                  {allowedPageSizes.map((v, i) => (
                    <MenuItem key={i} value={v} sx={{ fontSize: 12 }}>
                      {String(v)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant={'h5'} color={'textSecondary'}>
                items
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <MiniButton onClick={() => JumpToPage()}>Jump To</MiniButton>
              <Input
                type={'text'}
                placeholder={'Page No.'}
                size={'small'}
                value={inputPageNo}
                onChange={(e) => {
                  setInputPageNo(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    JumpToPage();
                    e.preventDefault();
                  }
                }}
                sx={{
                  width: 64,
                  fontSize: 12,
                  color: 'text.secondary',
                  '& :hover': {
                    color: 'text.primary',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardActions>
    </MainCard>
  );
}

export default PaginationCard;
