import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  TableSortLabel,
  Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { SmartLink } from '../abelcommon';
import PaginationCard from '../components/PaginationCard';
import ErrorCard from '../components/ErrorCard';
import QueryProcessor from '../components/QueryProcessor';
import { useSummary, useAddresses } from '../queries';
import { addressPrefixFromFingerprint, addressAbbrFromFingerprint } from './utils';

type ORDER = 'asc' | 'desc';
const allowedPageSizes = [10, 25, 50, 100];
const allowedOrderBy = ['a', 'txn', 'ctxn', 'ttxn', 'fcb', 'lcb', 'ftb', 'ltb'];
const allowedOrder = ['asc', 'desc'];

/* eslint-disable-next-line */
export interface ViewSelectedAddressesProps {}

export function ViewSelectedAddresses(props: ViewSelectedAddressesProps) {
  const [searchParams] = useSearchParams();

  let orderBy = searchParams.get('ob') ?? 'ltb';
  if (!allowedOrderBy.includes(orderBy)) {
    orderBy = 'ltb';
  }

  let order = searchParams.get('o') ?? 'desc';
  if (!allowedOrder.includes(order)) {
    order = 'desc';
  }

  let pageSize = Number(searchParams.get('ps') ?? 25);
  if (isNaN(pageSize) || !allowedPageSizes.includes(pageSize)) {
    pageSize = 25;
  }

  let pageNo = Number(searchParams.get('p') ?? 1);
  if (isNaN(pageNo) || pageNo < 1) {
    pageNo = 1;
  }

  const summaryQuery = useSummary();

  return (
    <QueryProcessor
      query={summaryQuery}
      dataValidator={(data: any) => data.type === 'summary'}
      renderer={(data: any) => {
        return (
          <ViewAddresses
            totalAddress={data.payload.totalAddress}
            orderBy={orderBy}
            order={order}
            pageSize={pageSize}
            pageNo={pageNo}
          />
        );
      }}
    />
  );
}

interface ViewAddressesProps {
  totalAddress: number;
  orderBy: string;
  order: string;
  pageSize: number;
  pageNo: number;
}

function ViewAddresses({ totalAddress, orderBy, order, pageSize, pageNo }: ViewAddressesProps) {
  const addressSeqnoBegin = (pageNo - 1) * pageSize;
  const addressSeqnoEnd = addressSeqnoBegin + pageSize - 1;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component={'span'} variant={'h2'}>
          Addresses{' '}
        </Typography>
        {
          <Typography component={'span'} variant={'h5'} color={'textSecondary'}>
            (total = {totalAddress.toLocaleString()})
          </Typography>
        }
      </Grid>
      <Grid item xs={12}>
        <PaginationCard totalItems={totalAddress} pageSize={pageSize} pageNo={pageNo}>
          <ViewAddressesContent
            orderBy={orderBy}
            order={order}
            addressSeqnoBegin={addressSeqnoBegin}
            addressSeqnoEnd={addressSeqnoEnd}
          />
        </PaginationCard>
      </Grid>
    </Grid>
  );
}

interface ViewAddressesContentProps {
  orderBy: string;
  order: string;
  addressSeqnoBegin: number;
  addressSeqnoEnd: number;
}

function ViewAddressesContent({
  orderBy,
  order,
  addressSeqnoBegin,
  addressSeqnoEnd,
}: ViewAddressesContentProps) {
  const fetchCount = 100;
  const startForTxSeqnoBegin = Math.floor(addressSeqnoBegin / fetchCount) * fetchCount;
  const startForTxSeqnoEnd = Math.floor(addressSeqnoEnd / fetchCount) * fetchCount;

  const qs = [
    useAddresses(orderBy, order, startForTxSeqnoBegin, fetchCount, {
      enabled: startForTxSeqnoBegin !== startForTxSeqnoEnd,
    }),
    useAddresses(orderBy, order, startForTxSeqnoEnd, fetchCount),
  ];

  if (qs.some((q) => q.isLoading) || qs.some((q) => q.isRefetching)) {
    return <LinearProgress color={'warning'} />;
  }

  if (qs.some((q) => q.isError) || qs.some((q) => !q.isSuccess)) {
    return (
      <ErrorCard enableGoBack={true}>
        <Typography variant={'h4'}>Failed fetching addresses data.</Typography>
      </ErrorCard>
    );
  }

  const data =
    startForTxSeqnoBegin === startForTxSeqnoEnd ? qs[0].data.payload : [...qs[0].data.payload, ...qs[1].data.payload];

  const dataBegin = addressSeqnoBegin - startForTxSeqnoBegin;
  const dataEnd = dataBegin + (addressSeqnoEnd - addressSeqnoBegin);
  return (
    <AddressList
      data={data.filter((d: any, idx: number) => dataBegin <= idx && idx <= dataEnd)}
      orderBy={orderBy}
      order={order as ORDER}
    />
  );
}

interface AddressListProps {
  data: any[];
  orderBy: string;
  order: ORDER;
}

function AddressList({ data, orderBy, order }: AddressListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams.toString());

  const JumpToPage = (ob: string) => (event: React.MouseEvent<unknown>) => {
    const prevOb = searchParams.get('ob');
    newSearchParams.set('ob', ob);
    newSearchParams.set('p', '1');
    if (prevOb === ob) {
      newSearchParams.set('o', order === 'desc' ? 'asc' : 'desc');
    } else {
      newSearchParams.set('o', ob === 'a' ? 'asc' : 'desc');
    }
    return setSearchParams(newSearchParams);
  };

  return (
    <TableContainer sx={{ width: { xs: 'auto', sm: 1 } }}>
      <Table
        sx={{
          width: { xs: 'max-content', sm: 1 },
          tableLayout: 'auto',
          '& .MuiTableCell-root': { px: 1, py: 1 },
        }}
      >
        <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
          <TableRow>
            <TableSortLabelCell label="Address" col="a" ob={orderBy} o={order} onClick={JumpToPage("a")} defaultSortDirection="asc" />
            <TableSortLabelCell label="Txn" col="txn" ob={orderBy} o={order} onClick={JumpToPage("txn")} align="right" />
            <TableSortLabelCell
              label="Coinbase Txn"
              col="ctxn"
              ob={orderBy}
              o={order}
              onClick={JumpToPage("ctxn")}
              align="right"
            />
            <TableSortLabelCell
              label="Transfer Txn"
              col="ttxn"
              ob={orderBy}
              o={order}
              onClick={JumpToPage("ttxn")}
              align="right"
            />
            <TableSortLabelCell
              label="First Mined Block"
              col="fcb"
              ob={orderBy}
              o={order}
              onClick={JumpToPage("fcb")}
              align="right"
            />
            <TableSortLabelCell
              label="Last Mined Block"
              col="lcb"
              ob={orderBy}
              o={order}
              onClick={JumpToPage("lcb")}
              align="right"
            />
            <TableSortLabelCell
              label="First Transfer Block"
              col="ftb"
              ob={orderBy}
              o={order}
              onClick={JumpToPage("ftb")}
              align="right"
            />
            <TableSortLabelCell
              label="Last Transfer Block"
              col="ltb"
              ob={orderBy}
              o={order}
              onClick={JumpToPage("ltb")}
              align="right"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d, i) => {
            const addressPrefix = addressPrefixFromFingerprint(d.fingerprint);
            const addressAbbr = addressAbbrFromFingerprint(d.fingerprint);
            return (
              <TableRow key={i}>
                <TableCell>
                  <SmartLink to={`/address/0x${addressPrefix}`} sx={{ fontFamily: 'Monospace' }}>
                    0x{addressAbbr}
                  </SmartLink>
                </TableCell>
                <TableCell align={'right'}>
                  <SmartLink to={`/txs/?pa=0x${addressPrefix}`}>
                    <Typography variant={'body2'}>{d.totalCoinbaseTx + d.totalTransferTx}</Typography>
                  </SmartLink>
                </TableCell>
                <TableCell align={'right'}>
                  {d.totalCoinbaseTx === 0 && 0}
                  {d.totalCoinbaseTx > 0 && (
                    <SmartLink to={`/txs/?type=coinbase&pa=0x${addressPrefix}`}>
                      <Typography variant={'body2'}>{d.totalCoinbaseTx}</Typography>
                    </SmartLink>
                  )}
                </TableCell>
                <TableCell align={'right'}>
                  {d.totalTransferTx === 0 && 0}
                  {d.totalTransferTx > 0 && (
                    <SmartLink to={`/txs/?type=transfer&pa=0x${addressPrefix}`}>
                      <Typography variant={'body2'}>{d.totalTransferTx}</Typography>
                    </SmartLink>
                  )}
                </TableCell>
                <TableCell align={'right'}>
                  {d.firstCoinbaseBlockHeight === -1 && 'N/A'}
                  {d.firstCoinbaseBlockHeight > -1 && (
                    <SmartLink to={`/block/${d.firstCoinbaseBlockHeight}`}>{d.firstCoinbaseBlockHeight}</SmartLink>
                  )}
                </TableCell>
                <TableCell align={'right'}>
                  {d.lastCoinbaseBlockHeight === -1 && 'N/A'}
                  {d.lastCoinbaseBlockHeight > -1 && (
                    <SmartLink to={`/block/${d.lastCoinbaseBlockHeight}`}>{d.lastCoinbaseBlockHeight}</SmartLink>
                  )}
                </TableCell>
                <TableCell align={'right'}>
                  {d.firstTransferBlockHeight === -1 && 'N/A'}
                  {d.firstTransferBlockHeight > -1 && (
                    <SmartLink to={`/block/${d.firstTransferBlockHeight}`}>{d.firstTransferBlockHeight}</SmartLink>
                  )}
                </TableCell>
                <TableCell align={'right'}>
                  {d.lastTransferBlockHeight === -1 && 'N/A'}
                  {d.lastTransferBlockHeight > -1 && (
                    <SmartLink to={`/block/${d.lastTransferBlockHeight}`}>{d.lastTransferBlockHeight}</SmartLink>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface TableSortLabelCellProps {
  col: string;
  ob: string;
  o: ORDER;
  label: string;
  onClick: (event: React.MouseEvent<unknown>) => void;
  align?: 'left' | 'right';
  defaultSortDirection?: 'asc' | 'desc';
}

const TableSortLabelCell = ({
  col,
  ob,
  o,
  label,
  onClick,
  align = 'left',
  defaultSortDirection = 'desc',
}: TableSortLabelCellProps) => {
  return (
    <TableCell align={align} sortDirection={ob === col ? o : false}>
      <TableSortLabel active={ob === col} direction={ob === col ? o : defaultSortDirection} onClick={onClick}>
        {label}
        {ob === col && (
          <Box component="span" sx={visuallyHidden}>
            {o === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        )}
      </TableSortLabel>
    </TableCell>
  );
};

export default ViewSelectedAddresses;
