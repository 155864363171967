import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  CardContent,
  CardActions,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Link,
  Divider,
  Button,
  capitalize,
} from '@mui/material';
import MainCard from '../../components/MainCard';
import { TextAvatar } from './misc';
import useTxs from '../../queries/useTxs';
import TimeAgo from '../../components/TimeAgo';
import { addressPrefixFromFingerprint, addressAbbrFromFingerprint } from '../utils';

const TxRow = ({ tx }: { tx: any }) => {
  const toFingerprint = tx.txtype === 'coinbase' ? tx.vout[0].scriptinfo.fingerprint : null;
  const toAddrPrefix = toFingerprint ? addressPrefixFromFingerprint(toFingerprint) : null;
  const toAddrAbbr = toFingerprint ? addressAbbrFromFingerprint(toFingerprint) : null;

  return (
    <TableRow>
      <TableCell align="left" sx={{ width: 40 }}>
        <TextAvatar text="TX" />
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ pl: 1 }}>
          <Link component={RouterLink} to={`/tx/0x${tx.hash}`}>
            0x{tx.hash.substring(0, 8)}...
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ pl: 1, fontSize: '0.8em' }}>
          <TimeAgo date={tx.time * 1000} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ fontSize: '0.9em' }}>
          From{' '}
          <Link component={RouterLink} to={'#'}>
            {tx.txtype === 'coinbase' ? '0x0 (Null Address)' : 'N/A'}
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '0.9em' }}>
          To{' '}
          <Link component={RouterLink} to={toAddrPrefix ? `/address/0x${toAddrPrefix}` : ''}>
            {toAddrAbbr ? `0x${toAddrAbbr}` : 'N/A'}
          </Link>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant={'body2'}>{capitalize(tx.txtype)}</Typography>
        <Typography variant={'body2'}>{`${tx.fee} ABEL${tx.txtype === 'coinbase' ? '' : ' (Gas)'}`}</Typography>
      </TableCell>
    </TableRow>
  );
};

const LatestTransactionsContent = () => {
  const latestTxsQuery = useTxs(-1, '', 'any');
  if (latestTxsQuery.isLoading) {
    return <Typography variant="body1">Loading...</Typography>;
  }
  if (latestTxsQuery.isError) {
    return <Typography variant="body1">Request failed.</Typography>;
  }

  const txs = latestTxsQuery.data.payload;
  return (
    <TableContainer>
      <Table sx={{ '& .MuiTableCell-root': { px: 0, py: 1 } }}>
        <TableBody>
          {Array.from(Array(txs.length).keys())
            .reverse()
            .map((i) => (
              <TxRow key={i} tx={txs[i]} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const LatestTransactions = () => {
  return (
    <MainCard title="Latest Transactions">
      <CardContent sx={{ height: 380, maxHeight: 380, overflow: 'auto' }}>
        <LatestTransactionsContent />
      </CardContent>
      <Divider />
      <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button variant="contained" href="/txs" size="small" sx={{ width: 1, height: 24, textTransform: 'none' }}>
          View all transactions
        </Button>
      </CardActions>
    </MainCard>
  );
};

export default LatestTransactions;
