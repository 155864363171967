import { useParams } from 'react-router-dom';
import RichList, { RichListItem } from '../components/RichList';
import { CardContent, Grid, Typography } from '@mui/material';
import { useAddress } from '../queries';
import QueryProcessor from '../components/QueryProcessor';
import { TabbedMainCard } from '../components/MainCard';
import { SmartLink } from '../abelcommon';

function useAddressWithPrecheck(address?: string) {
  if (!address || !address.startsWith('0xabe1')) {
    throw new Error('Illegal address.');
  }
  return useAddress(address);
}

function AddressOverview({ d }: { d: any }) {
  const { address } = useParams();

  return (
    <RichList sx={{ width: 1, mt: -2 }}>
      <RichListItem
        title={'Address (Short)'}
        help={'The 66-byte short address in which the last 32 bytes are unknown to the public.'}
      >
        <Typography variant={'body2'}>
          {address}
          {address && address.length < 134 && '*'.repeat(134 - address.length)}
        </Typography>
      </RichListItem>

      <RichListItem title={'Transactions'} help={'The total number of transactions involving this address.'}>
        <SmartLink to={`/txs/?pa=${address}`}>
          <Typography variant={'body2'}>{d.totalCoinbaseTx + d.totalTransferTx}</Typography>
        </SmartLink>
      </RichListItem>

      <RichListItem
        title={'Coinbase Transactions'}
        help={'The total number of coinbase transactions involving this address.'}
      >
        {d.totalCoinbaseTx === 0 && 0}
        {d.totalCoinbaseTx > 0 && (
          <SmartLink to={`/txs/?type=coinbase&pa=${address}`}>
            <Typography variant={'body2'}>{d.totalCoinbaseTx}</Typography>
          </SmartLink>
        )}
      </RichListItem>

      <RichListItem
        title={'Transfer Transactions'}
        help={'The total number of transfer transactions involving this address.'}
      >
        {d.totalTransferTx === 0 && 0}
        {d.totalTransferTx > 0 && (
          <SmartLink to={`/txs/?type=transfer&pa=${address}`}>
            <Typography variant={'body2'}>{d.totalTransferTx}</Typography>
          </SmartLink>
        )}
      </RichListItem>

      <RichListItem title={'First Mined Block'} help={'The height of the earliest block mined by this address.'}>
        {d.firstCoinbaseBlockHeight < 0 && 'N/A'}
        {d.firstCoinbaseBlockHeight >= 0 && (
          <SmartLink to={`/block/${d.firstCoinbaseBlockHeight}`}>
            <Typography variant={'body2'}>{d.firstCoinbaseBlockHeight}</Typography>
          </SmartLink>
        )}
      </RichListItem>

      <RichListItem title={'Last Mined Block'} help={'The height of the latest block mined by this address.'}>
        {d.lastCoinbaseBlockHeight < 0 && 'N/A'}
        {d.lastCoinbaseBlockHeight >= 0 && (
          <SmartLink to={`/block/${d.lastCoinbaseBlockHeight}`}>
            <Typography variant={'body2'}>{d.lastCoinbaseBlockHeight}</Typography>
          </SmartLink>
        )}
      </RichListItem>

      <RichListItem
        title={'First Transfer Block'}
        help={'The height of the block containing the earliest transfer transaction involving this address.'}
      >
        {d.firstTransferBlockHeight < 0 && 'N/A'}
        {d.firstTransferBlockHeight >= 0 && (
          <SmartLink to={`/block/${d.firstTransferBlockHeight}`}>
            <Typography variant={'body2'}>{d.firstTransferBlockHeight}</Typography>
          </SmartLink>
        )}
      </RichListItem>

      <RichListItem
        title={'Last Transfer Block'}
        help={'The height of the block containing the latest transfer transaction involving this address.'}
      >
        {d.lastTransferBlockHeight < 0 && 'N/A'}
        {d.lastTransferBlockHeight >= 0 && (
          <SmartLink to={`/block/${d.lastTransferBlockHeight}`}>
            <Typography variant={'body2'}>{d.lastTransferBlockHeight}</Typography>
          </SmartLink>
        )}
      </RichListItem>
    </RichList>
  );
}

const ViewAddress = () => {
  let { address } = useParams();
  if (address && !address.startsWith('0x')) {
    address = '0x' + address;
  }

  return (
    <QueryProcessor
      query={useAddressWithPrecheck(address)}
      dataValidator={(data: any) => data.type === 'address'}
      renderer={(data: any) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component={'span'} variant={'h2'}>
              Address Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TabbedMainCard labels={['Overview']}>
              <CardContent>
                <AddressOverview d={data.payload} />
              </CardContent>
            </TabbedMainCard>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ViewAddress;
