import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { SxProps, Grid, Divider, Box, Avatar, Typography, Link } from '@mui/material';
import MainCard from '../../components/MainCard';
import { TextLoading, TextRequestFailed } from './misc';
import useSummary from '../../queries/useSummary';
import TimeAgo from '../../components/TimeAgo';

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PaidIcon from '@mui/icons-material/Paid';
import DnsIcon from '@mui/icons-material/Dns';
import GrainIcon from '@mui/icons-material/Grain';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ReceiptIcon from '@mui/icons-material/Receipt';

const sxCentering: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type StatSubcardProps = {
  children?: React.ReactNode;
  sx?: SxProps;
};

const StatSubcard = ({ children, sx }: StatSubcardProps) => {
  return (
    <Box
      sx={{
        height: 1,
        display: 'flex',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

const makeStatAvatar = (IconComponent: any) => {
  return (
    <Avatar sx={{ mx: 2, width: '40', height: '40', backgroundColor: 'inherit' }}>
      <IconComponent sx={{ width: '32px', height: '32px', opacity: 0.8 }} color="primary" />
    </Avatar>
  );
};

type StatSubcardContentProps = {
  theme: any;
  data: any;
};

const A1Stat = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(LocalFireDepartmentIcon)}
      <Box sx={{ width: 150 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          LATEST BLOCK
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link component={RouterLink} to={`/block/${data.latestBlock.height}`}>
            {data.latestBlock.height}
          </Link>
          <Typography sx={{ ml: 1, fontSize: '0.7em', color: 'gray' }}>
            (<TimeAgo date={data.latestBlock.time * 1000} />)
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, mr: 2 }}>
        <Typography variant="h5" sx={{ color: 'gray', display: 'flex', justifyContent: 'flex-end' }}>
          BLOCK TIME
        </Typography>
        <Typography sx={{ color: 'gray', display: 'flex', justifyContent: 'flex-end' }}>
          {`${data.latestBlockTime} secs`}
        </Typography>
      </Box>
    </>
  );
};

const A2Stat = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(AccessAlarmIcon)}
      <Box sx={{ width: 1 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          AVERAGE BLOCK TIME
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            {`${data.avgBlockTimeLast1k.toFixed(1)} secs`}
          </Typography>
          <Typography sx={{ display: 'inline', ml: 1, fontSize: '0.7em', color: 'gray' }}>
            (OVER THE LATEST 1,000 BLOCKS)
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const B1Stat = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(ReceiptIcon)}
      <Box sx={{ width: 1 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          TOTAL LEDGER SIZE
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            {Math.round(data.totalFullSize / 1024 / 1024).toLocaleString() + ' MB'}
          </Typography>
          <Typography sx={{ display: 'inline', ml: 1, fontSize: '0.7em', color: 'gray' }}>
            {'(' + data.totalFullSize.toLocaleString() + ' bytes)'}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const B1StatCandidate = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(DnsIcon)}
      <Box sx={{ width: 1 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          TOTAL NUMBER OF MINING SITES
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>{data.numMiners.toLocaleString()}</Typography>
        </Box>
      </Box>
      {/* <Box sx={{ flexGrow: 1, mr: 2 }}>
        <Typography
          variant="h5"
          sx={{ color: 'gray', display: 'flex', justifyContent: 'flex-end' }}
        >
          ACTIVE SITES
        </Typography>
        <Typography
          sx={{ color: 'gray', display: 'flex', justifyContent: 'flex-end' }}
        >
          {data.numActiveMiners.toLocaleString()}
        </Typography>
      </Box> */}
    </>
  );
};

const B2Stat = ({ theme, data }: StatSubcardContentProps) => {
  const avgNetworkHashRate = data.networkHashRateLast1K / 10 ** 9
  return (
    <>
      {makeStatAvatar(GrainIcon)}
      <Box sx={{ width: 1 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          AVERAGE NETWORK HASH RATE
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            {Number(avgNetworkHashRate.toFixed(2)).toLocaleString()} GH/s
          </Typography>
          <Typography sx={{ display: 'inline', ml: 1, fontSize: '0.7em', color: 'gray' }}>
            (OVER THE LATEST 1,000 BLOCKS)
          </Typography>
        </Box>
      </Box>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const B2StatCandidate = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(GrainIcon)}
      <Box sx={{ width: 150 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          DIFFICULTY
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>{data.latestBlock.difficulty} TH</Typography>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, mr: 2 }}>
        <Typography variant="h5" sx={{ color: 'gray', display: 'flex', justifyContent: 'flex-end' }}>
          HASH RATE
        </Typography>
        <Typography sx={{ color: 'gray', display: 'flex', justifyContent: 'flex-end' }}>
          {data.hashRate} GH/s
        </Typography>
      </Box>
    </>
  );
};

const C1Stat = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(PaidIcon)}
      <Box sx={{ width: 1 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          TOTAL NUMBER OF TRANSACTIONS
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            <Link component={RouterLink} to="/txs">
              {data.totalTxs.toLocaleString()}
            </Link>
          </Typography>
          {/*<Link component={RouterLink} to="/txs">{data.numTxs.toLocaleString()}</Link>*/}
          {/*<Typography sx={{display: 'inline', ml: 1, fontSize: '0.7em', color: 'gray'}}>*/}
          {/*  ({data.avgTps} TPS)*/}
          {/*</Typography>*/}
        </Box>
      </Box>
      {/*<Box sx={{flexGrow: 1, mr: 2}}>*/}
      {/*  <Typography variant="h5" sx={{color: 'gray', display: 'flex', justifyContent: 'flex-end'}}>*/}
      {/*    TOTAL FEES*/}
      {/*  </Typography>*/}
      {/*  <Typography sx={{color: 'gray', display: 'flex', justifyContent: 'flex-end'}}>*/}
      {/*    {data.totalFees} ABEL*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
    </>
  );
};

const C2Stat = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(AccountBalanceWalletIcon)}
      <Box sx={{ width: 300 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          TOTAL NUMBER OF ADDRESSES
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            <Link component={RouterLink} to="/addresses">
              {data.totalAddress.toLocaleString()}
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const C2StatCandidate = ({ theme, data }: StatSubcardContentProps) => {
  return (
    <>
      {makeStatAvatar(AlternateEmailIcon)}
      <Box sx={{ width: 300 }}>
        <Typography variant="h5" sx={{ width: 1, color: 'gray' }}>
          ACTIVE ADDRESSES IN 3 MONTHS
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: theme.palette.primary.main }}>{data.numAddresses.toLocaleString()}</Typography>
        </Box>
      </Box>
    </>
  );
};

const ImportantNumbers = () => {
  const theme = useTheme();
  const summaryQuery = useSummary();
  if (summaryQuery.isLoading) {
    return TextLoading;
  }
  if (summaryQuery.isError) {
    return TextRequestFailed;
  }
  const summary = summaryQuery.data.payload;

  return (
    <MainCard sx={{ width: 1 }}>
      <Grid container spacing={0} sx={{ width: 1 }}>
        <Grid item xs={12} sm={5.9} md={3.9}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ height: 70 }}>
              <StatSubcard sx={{ pt: 2 }}>
                <A1Stat theme={theme} data={summary} />
              </StatSubcard>
            </Grid>
            <Grid item xs={12} sx={{ height: 20 }}>
              <Box sx={{ ...sxCentering, height: 1, px: 2 }}>
                <Divider orientation="horizontal" sx={{ width: 1 }} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ height: 70 }}>
              <StatSubcard sx={{ pt: 1 }}>
                <A2Stat theme={theme} data={summary} />
              </StatSubcard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0.2} md={0.15} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Box sx={{ ...sxCentering, height: 160, py: 2 }}>
            <Divider orientation="vertical" />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Box sx={{ ...sxCentering, height: 1, px: 2 }}>
            <Divider orientation="horizontal" sx={{ width: 1 }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5.9} md={3.9}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ height: 70 }}>
              <StatSubcard sx={{ pt: 2 }}>
                <B1Stat theme={theme} data={summary} />
              </StatSubcard>
            </Grid>
            <Grid item xs={12} sx={{ height: 20 }}>
              <Box sx={{ ...sxCentering, height: 1, px: 2 }}>
                <Divider orientation="horizontal" sx={{ width: 1 }} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ height: 70 }}>
              <StatSubcard sx={{ pt: 1 }}>
                <B2Stat theme={theme} data={summary} />
              </StatSubcard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0.2} md={0.15} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ ...sxCentering, height: 160, py: 2 }}>
            <Divider orientation="vertical" />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box sx={{ ...sxCentering, height: 1, px: 2 }}>
            <Divider orientation="horizontal" sx={{ width: 1 }} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={5.9} md={3.9}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ height: 70 }}>
              <StatSubcard sx={{ pt: 2 }}>
                <C1Stat theme={theme} data={summary} />
              </StatSubcard>
            </Grid>
            <Grid item xs={12} sx={{ height: 20 }}>
              <Box sx={{ ...sxCentering, height: 1, px: 2 }}>
                <Divider orientation="horizontal" sx={{ width: 1 }} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ height: 70 }}>
              <StatSubcard sx={{ pt: 1 }}>
                <C2Stat theme={theme} data={summary} />
              </StatSubcard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ImportantNumbers;
