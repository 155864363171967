import React from 'react';
import { Grid } from '@mui/material';
import FooterBanner from './FooterBanner';
import FooterSitemap from './FooterSitemap';

export const FooterContent = () => {
  return (
    <Grid
      container
      rowSpacing={2}
      sx={{ width: 1, pl: 2, pr: 2, pt: 1, pb: 6 }}
    >
      <Grid item sm={12} md={4} sx={{ width: 1 }}>
        <FooterBanner />
      </Grid>
      <Grid item sm={12} md={8} sx={{ width: 1 }}>
        <FooterSitemap />
      </Grid>
    </Grid>
  );
};

export default FooterContent;
