import axios from 'axios';
import { useQuery } from 'react-query';
import { environment } from '../config/environment';

export const fetchBlock = (block_height_or_hash: number | string) =>
  axios
    .get(`${environment.apiBase}/block/${block_height_or_hash}`)
    .then((res) => res.data);

export const useBlock = (block_height_or_hash: number | string) => {
  let k = block_height_or_hash;
  if (
    typeof block_height_or_hash === 'string' &&
    !block_height_or_hash.startsWith('0x')
  ) {
    k = Number(k);
  }
  return useQuery(['block', k], () => fetchBlock(k), {
    retry: false,
    staleTime: 3600 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useBlock;
