import { Outlet, useRoutes, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import AbelScanLayout from '../layout';
import layoutConfig from '../config/layoutConfig';
import { UnexpectedErrorCard, PageNotFoundCard } from '../components/ErrorCard';

import Dashboard from './Dashboard';
import ViewBlock from './ViewBlock';
import Search from './Search';
import ViewAllBlocks from './ViewAllBlocks';
import ViewSelectedTxs from './ViewSelectedTxs';
import ViewTx from './ViewTx';
import AdminDashboard from './AdminDashboard';
import ViewAddress from './ViewAddress';
import ViewSelectedAddresses from './ViewSeletedAddresses';

const routes_mapping = [
  {
    path: '/',
    element: (
      <AbelScanLayout config={layoutConfig}>
        <Outlet />
      </AbelScanLayout>
    ),
    children: [
      { index: true, element: <Navigate to={'home'} replace /> },
      { path: 'home', element: <Dashboard /> },
      { path: 'search', element: <Search /> },
      { path: 'blocks', element: <ViewAllBlocks /> },
      { path: 'block/:block_height_or_hash', element: <ViewBlock /> },
      { path: 'txs', element: <ViewSelectedTxs /> },
      { path: 'tx/:txid', element: <ViewTx /> },
      { path: 'addresses', element: <ViewSelectedAddresses /> },
      { path: 'address/:address', element: <ViewAddress /> },
      { path: 'admin', element: <AdminDashboard /> },
      { path: '*', element: <PageNotFoundCard /> },
    ],
  },
];

const App = () => {
  return <ErrorBoundary FallbackComponent={UnexpectedErrorCard}>{useRoutes(routes_mapping)}</ErrorBoundary>;
};

export default App;
