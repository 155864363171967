import React from 'react';
import {
  PaletteMode,
  Theme,
  ThemeOptions,
  createTheme,
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from '@mui/material';
import { red, blue } from '@mui/material/colors';
import { useRecoilState } from 'recoil';
import { persistentAtom } from '../abelcommon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function LD(mode: PaletteMode, light: any, dark: any): any {
  return mode === 'light' ? light : dark;
}

function makePaletteOptions(mode: PaletteMode) {
  return {
    mode,
    primary: {
      main: LD(mode, blue.A400, blue.A200),
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  };
}

function makeTypographyOptions(mode: PaletteMode) {
  return {
    h1: {
      fontSize: 24,
      fontWeight: 500,
    },
    h2: {
      fontSize: 20,
      fontWeight: 500,
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontSize: 13,
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 13,
    },
  };
}

function makeMixinsOptions(mode: PaletteMode) {
  return {
    toolbar: {
      minHeight: '48px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  };
}

function makeComponentsOptions(mode: PaletteMode) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: LD(mode, '#DDD', '#333'),
          wordWrap: 'break-word',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: LD(mode, '#B0B0B0', '#404040'),
          ':hover': {
            backgroundColor: '#808080',
          },
        },
      },
    },
  };
}

function makeOtherOptions(mode: PaletteMode) {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 1050,
        lg: 1200,
        xl: 1400,
      },
    },
  };
}

function makeThemeOptions(mode: PaletteMode): ThemeOptions {
  return {
    palette: makePaletteOptions(mode),
    typography: makeTypographyOptions(mode),
    mixins: makeMixinsOptions(mode),
    components: makeComponentsOptions(mode),
    ...makeOtherOptions(mode),
  };
}

export const paletteModeState = persistentAtom<PaletteMode>({
  key: 'theme-palette-mode',
  default: 'dark',
});

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [paletteMode] = useRecoilState<PaletteMode>(paletteModeState);
  const themeOptions = makeThemeOptions(paletteMode);

  const theme: any = React.useMemo<Theme>(() => createTheme(themeOptions), [themeOptions]);
  theme.components = themeOptions.components;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
