import React from 'react';
import { Box, Paper, Container } from '@mui/material';
import { useConfig } from '../config';
import FooterTopbar from './FooterTopbar';
import FooterContent from './FooterContent';

export const FooterReserved = () => {
  const c = useConfig();
  return (
    <Box
      id={'placeholder-footer-reserved'}
      sx={{ width: 1, mt: 'auto', height: c.footer.barHeight.mini }}
    />
  );
};

export const Footer = () => {
  const c = useConfig();
  return (
    <Box id={'wrapper-footer'} sx={{ mt: '-' + c.footer.barHeight.mini }}>
      <Paper
        component={'footer'}
        sx={{ borderRadius: 0, minHeight: c.footer.barHeight.full }}
      >
        <Container maxWidth={c.maxWidth} disableGutters>
          <FooterTopbar />
          <FooterContent />
        </Container>
      </Paper>
    </Box>
  );
};

export default Footer;
