import { KeyedObject } from '../abelcommon';
import axios from 'axios';
import { useQuery } from 'react-query';
import { environment } from '../config/environment';

export function fetchAddresses(orderBy?: string, order?: string, start?: number, count?: number) {
  const searchParams = new URLSearchParams();
  if (orderBy !== undefined) {
    searchParams.append('order_by', String(orderBy));
  }
  if (order !== undefined) {
    searchParams.append('order', String(order));
  }
  if (start !== undefined) {
    searchParams.append('start', String(start));
  }
  if (count !== undefined) {
    searchParams.append('count', String(count));
  }
  return axios.get(`${environment.apiBase}/addresses?${searchParams.toString()}`).then((res) => res.data);
}

export function useAddresses(orderBy?: string, order?: string, start?: number, count?: number, options?: KeyedObject) {
  return useQuery(['addresses', orderBy, order, start, count], () => fetchAddresses(orderBy, order, start, count), {
    retry: false,
    staleTime: 1000 * 300,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
    ...options,
  });
}

export default useAddresses;
