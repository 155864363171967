import React from 'react';
import { useRecoilState } from 'recoil';
import { persistentAtom } from '../abelcommon';
import {
  Dialog,
  DialogContent,
  TextField,
} from '@mui/material';
import md5 from 'md5';

const localTokenState = persistentAtom<string>({
  key: 'LocalAuthGuard-access-token',
  default: '',
});

function authenticate(token: string) {
  // token === 'AbelAdmin'
  return md5(token) === 'ffdb7ff5a178d4329adfe78d1013d5c4'; 
}

/* eslint-disable-next-line */
export interface LocalAuthGuardProps {
  children?: React.ReactElement | React.ReactElement[];
}

export function LocalAuthGuard({ children }: LocalAuthGuardProps) {
  const [localToken, setLocalToken] = useRecoilState(localTokenState);
  const [inputToken, setInputToken] = React.useState('');
  const [authenticated, setAuthenticated] = React.useState(authenticate(localToken));

  if (!authenticated) {
    return (
      <Dialog open={!authenticated}>
        <DialogContent>
          <TextField
            autoFocus
            margin={'dense'}
            id={'access-token'}
            label={'Access Token'}
            fullWidth
            variant={'standard'}
            value={inputToken}
            onChange={(e) => setInputToken(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setLocalToken(inputToken);
                setAuthenticated(authenticate(inputToken));
              }
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return <>{children}</>;
}

export default LocalAuthGuard;
