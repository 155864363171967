import React from 'react';
import { SxProps, TextField } from '@mui/material';
import { KeyedObject } from '../abelcommon';

export type JSONTextFieldProps = KeyedObject & {
  value?: unknown;
  space?: number;
  sx?: SxProps;
};

export const JSONTextField = React.forwardRef(
  ({ value, space = 2, sx, ...others }: JSONTextFieldProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <TextField
        ref={ref}
        type={'text'}
        variant={'outlined'}
        multiline
        value={JSON.stringify(value, null, space)}
        inputProps={{ readOnly: true, sx: { fontFamily: 'monospace' } }}
        sx={{ width: 1, ...sx }}
        {...others}
      />
    );
  }
);

export default JSONTextField;
