import React from 'react';
import { KeyedObject } from '../../abelcommon';
import { Typography, TypographyProps, useTheme } from '@mui/material';
import { ReactNodeMaker } from '.';

export type SmartNodeProps = KeyedObject & {
  value: React.ReactNode | ReactNodeMaker | string | null;
  typographyProps?: TypographyProps;
  nullable?: boolean;
  component?: any;
};

export const SmartNode = React.forwardRef(
  (
    {
      value,
      typographyProps = {},
      nullable = false,
      component,
      ...componentProps
    }: SmartNodeProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();
    let node: any = value;
    if (typeof node === 'function') {
      node = node(theme);
    }

    if (typeof node === 'string') {
      node = <Typography {...typographyProps}>{node}</Typography>;
    }

    if (!node && nullable) {
      return null;
    }

    if (!component) {
      return node;
    }

    const Component = component;
    return (
      <Component ref={ref} {...componentProps}>
        {node}
      </Component>
    );
  }
);

export default SmartNode;
