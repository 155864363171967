import { useSearchParams } from 'react-router-dom';
import {
  Typography,
  Grid,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
} from '@mui/material';
import { SmartLink, formatDurationAbbr, formatRFC7231 } from '../abelcommon';
import MainCard from '../components/MainCard';
import PaginationCard from '../components/PaginationCard';
import ErrorCard from '../components/ErrorCard';
import QueryProcessor from '../components/QueryProcessor';
import { useSummary, useTxs, useAddress } from '../queries';
import { addressPrefixFromFingerprint, addressAbbrFromFingerprint } from './utils';

const allowedTxTypes = ['any', 'coinbase', 'transfer'];
const allowedPageSizes = [10, 25, 50, 100];

/* eslint-disable-next-line */
export interface ViewSelectedTxsProps {}

export function ViewSelectedTxs(props: ViewSelectedTxsProps) {
  const [searchParams] = useSearchParams();

  const blockHeight = Number(searchParams.get('block') ?? -1);
  const address = searchParams.get('pa') ?? '';
  if (!isNaN(blockHeight) && blockHeight >= 0) {
    return <ViewBlockTxs blockHeight={blockHeight} />;
  } else if (address.length > 0) {
    return <ViewBlockAddress address={address} />;
  } else {
    return <ViewHistTxs />;
  }
}

interface ViewBlockTxsProps {
  blockHeight: number;
}

function ViewBlockTxs({ blockHeight }: ViewBlockTxsProps) {
  const txsQuery = useTxs(blockHeight, '', 'any', undefined, 100);
  return (
    <QueryProcessor
      query={txsQuery}
      dataValidator={(data: any) => data.type === 'txs'}
      renderer={(data: any) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component={'span'} variant={'h2'}>
              Transactions{' '}
            </Typography>
            <Typography component={'span'} variant={'h5'} color={'textSecondary'}>
              in block #{blockHeight}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MainCard>
              <CardContent>
                <TxList data={data.payload} />
              </CardContent>
            </MainCard>
          </Grid>
        </Grid>
      )}
    />
  );
}

interface ViewBlockAddressProps {
  address: string;
}

function ViewBlockAddress({ address }: ViewBlockAddressProps) {
  const [searchParams] = useSearchParams();
  let txType = searchParams.get('type') ?? 'any';
  if (!allowedTxTypes.includes(txType)) {
    txType = 'any';
  }

  let pageSize = Number(searchParams.get('ps') ?? 25);
  if (isNaN(pageSize) || !allowedPageSizes.includes(pageSize)) {
    pageSize = 25;
  }

  let pageNo = Number(searchParams.get('p') ?? 1);
  if (isNaN(pageNo) || pageNo < 1) {
    pageNo = 1;
  }

  const addressQuery = useAddress(address);

  return (
    <QueryProcessor
      query={addressQuery}
      dataValidator={(data: any) => data.type === 'address'}
      renderer={(data: any) => {
        const d = data.payload;
        let totalTxs = 0;
        if (txType === 'coinbase') {
          totalTxs = d.totalCoinbaseTx;
        } else if (txType === 'transfer') {
          totalTxs = d.totalTransferTx;
        } else {
          totalTxs = d.totalCoinbaseTx + d.totalTransferTx;
        }
        return <ViewTxs address={address} txType={txType} totalTxs={totalTxs} pageSize={pageSize} pageNo={pageNo} />;
      }}
    />
  );
}

/* eslint-disable-next-line */
interface ViewHistTxsProps {}

function ViewHistTxs(props: ViewHistTxsProps) {
  const [searchParams] = useSearchParams();
  let txType = searchParams.get('type') ?? 'any';
  if (!allowedTxTypes.includes(txType)) {
    txType = 'any';
  }

  let pageSize = Number(searchParams.get('ps') ?? 25);
  if (isNaN(pageSize) || !allowedPageSizes.includes(pageSize)) {
    pageSize = 25;
  }

  let pageNo = Number(searchParams.get('p') ?? 1);
  if (isNaN(pageNo) || pageNo < 1) {
    pageNo = 1;
  }

  const summaryQuery = useSummary();

  return (
    <QueryProcessor
      query={summaryQuery}
      dataValidator={(data: any) => data.type === 'summary'}
      renderer={(data: any) => {
        const d = data.payload;
        let totalTxs = 0;
        if (txType === 'coinbase') {
          totalTxs = d.latestBlock.height + 1;
        } else if (txType === 'transfer') {
          totalTxs = d.totalTransactions - (d.latestBlock.height + 1);
        } else {
          totalTxs = d.totalTxs;
        }
        return <ViewTxs address="" txType={txType} totalTxs={totalTxs} pageSize={pageSize} pageNo={pageNo} />;
      }}
    />
  );
}

interface ViewTxsProps {
  address: string;
  txType: string;
  totalTxs: number;
  pageSize: number;
  pageNo: number;
}

function ViewTxs({ address, txType, totalTxs, pageSize, pageNo }: ViewTxsProps) {
  const txSeqnoEnd = totalTxs - (pageNo - 1) * pageSize - 1;
  const txSeqnoBegin = Math.max(0, txSeqnoEnd - pageSize + 1);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component={'span'} variant={'h2'}>
          Transactions{' '}
        </Typography>
        {
          <Typography component={'span'} variant={'h5'} color={'textSecondary'}>
            (type = {txType}, total = {totalTxs.toLocaleString()}
            {address.length > 0 ? `, address = ${address}${address.length === 70 ? '...****' : ''}` : ''})
          </Typography>
        }
      </Grid>
      <Grid item xs={12}>
        <PaginationCard totalItems={totalTxs} pageSize={pageSize} pageNo={pageNo}>
          <ViewTxsContent
            address={address}
            txType={txType}
            totalTxs={totalTxs}
            txSeqnoBegin={txSeqnoBegin}
            txSeqnoEnd={txSeqnoEnd}
          />
        </PaginationCard>
      </Grid>
    </Grid>
  );
}

interface ViewTxsContentProps {
  address: string;
  txType: string;
  totalTxs: number;
  txSeqnoBegin: number;
  txSeqnoEnd: number;
}

function ViewTxsContent({ address, txType, totalTxs, txSeqnoBegin, txSeqnoEnd }: ViewTxsContentProps) {
  const fetchCount = 100;
  const startForTxSeqnoBegin = Math.floor(txSeqnoBegin / fetchCount) * fetchCount;
  const startForTxSeqnoEnd = Math.floor(txSeqnoEnd / fetchCount) * fetchCount;

  const qs = [
    useTxs(-1, address, txType, startForTxSeqnoBegin, fetchCount, {
      enabled: startForTxSeqnoBegin !== startForTxSeqnoEnd,
    }),
    useTxs(-1, address, txType, startForTxSeqnoEnd, fetchCount),
  ];

  if (qs.some((q) => q.isLoading) || qs.some((q) => q.isRefetching)) {
    return <LinearProgress color={'warning'} />;
  }

  if (qs.some((q) => q.isError) || qs.some((q) => !q.isSuccess)) {
    return (
      <ErrorCard enableGoBack={true}>
        <Typography variant={'h4'}>Failed fetching transactions data.</Typography>
      </ErrorCard>
    );
  }

  const data =
    startForTxSeqnoBegin === startForTxSeqnoEnd ? qs[0].data.payload : [...qs[0].data.payload, ...qs[1].data.payload];

  const dataBegin = txSeqnoBegin - startForTxSeqnoBegin;
  const dataEnd = dataBegin + (txSeqnoEnd - txSeqnoBegin);
  return <TxList data={data.filter((d: any, idx: number) => dataBegin <= idx && idx <= dataEnd)} />;
}

interface TxListProps {
  data: any[];
}

function TxList({ data }: TxListProps) {
  // Sort by block height and tx type reversely.
  data.sort((a, b) => {
    if (a.blockheight !== b.blockheight) {
      return b.blockheight - a.blockheight;
    } else {
      return a.txtype === 'coinbase' ? -1 : b.txtype === 'coinbase' ? 1 : 0;
    }
  });

  const ts = new Date();
  return (
    <TableContainer sx={{ width: { xs: 'auto', sm: 1 } }}>
      <Table
        sx={{
          width: { xs: 'max-content', sm: 1 },
          tableLayout: 'auto',
          '& .MuiTableCell-root': { px: 1, py: 1 },
        }}
      >
        <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
          <TableRow>
            <TableCell>Hash (txid)</TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align={'right'}>{'From -> To'}</TableCell>
            <TableCell align={'right'}>Time</TableCell>
            <TableCell align={'right'}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d, i) => {
            const dts = d.time * 1000;
            // const [coinbaseAddrHash, coinbaseAddrFingerprint] = extractCoinbaseAddrInfo(d);
            const coinbaseToFingerprint = d.txtype === 'coinbase' ? d.vout[0].scriptinfo.fingerprint : null;
            const coinbaseToAddrPrefix = coinbaseToFingerprint
              ? addressPrefixFromFingerprint(coinbaseToFingerprint)
              : null;
            const coinbaseToAddrAbbr = coinbaseToFingerprint ? addressAbbrFromFingerprint(coinbaseToFingerprint) : null;
            return (
              <TableRow key={i}>
                <TableCell>
                  <SmartLink to={`/tx/0x${d.hash}`}>
                    <Typography variant={'body2'} sx={{ fontFamily: 'Monospace' }}>
                      0x{d.hash.slice(0, 8) + '..' + d.hash.slice(-8)}
                    </Typography>
                  </SmartLink>
                </TableCell>
                <TableCell>
                  <SmartLink to={`/block/${d.blockheight}`}>{d.blockheight}</SmartLink>
                </TableCell>
                <TableCell>{formatDurationAbbr(dts, ts)} ago</TableCell>
                <TableCell>{d.txtype}</TableCell>
                <TableCell align={'right'}>
                  {d.txtype === 'coinbase' && (
                    <Typography variant={'body2'} sx={{ fontFamily: 'Monospace' }}>
                      {'0x0 -> '}
                      <SmartLink to={`/address/0x${coinbaseToAddrPrefix}`}>0x{coinbaseToAddrAbbr}</SmartLink>
                    </Typography>
                  )}
                  {d.txtype !== 'coinbase' && (
                    <Typography variant={'body2'} sx={{ fontFamily: 'Monospace' }}>
                      <SmartLink to={`/tx/0x${d.hash}`}>{`${d.vin.length} Input${d.vin.length > 1 ? 's' : ''} -> ${
                        d.vout.length
                      } Output${d.vout.length > 1 ? 's' : ''}`}</SmartLink>
                    </Typography>
                  )}
                </TableCell>
                <TableCell align={'right'}>{formatRFC7231(dts).substring(5)}</TableCell>
                <TableCell align={'right'}>{d.fee} ABEL</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ViewSelectedTxs;
