import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { formatDurationAbbr } from '../../abelcommon';
import QueryProcessor from '../../components/QueryProcessor';
import { JSONTextField } from '../../components/JSONTextField';
import { useNetwork, useNetworkSeeds } from '../../queries';

function formatNetworkData(data: any) {
  const ts = new Date();
  return data.payload
    .filter((d: any) => d.blocks !== null)
    .map((d: any, idx: number) => {
      return {
        id: idx,
        address: d.address,
        lastSuccess: d.lastSuccess,
        lastUpdated: formatDurationAbbr(d.lastSuccess * 1000, ts),
        version: d.version,
        subVersion: d.subVersion,
        blocks: d.blocks,
        a1d: d['1d'],
        a7d: d['7d'],
        a30d: d['30d'],
        ...d.ipInfo,
      };
    })
    .sort((a: any, b: any) => b.lastSuccess - a.lastSuccess);
}

function statCity(nd: any) {
  return nd.reduce((stat: any, d: any) => {
    return {
      ...stat,
      [d.city]: (stat[d.city] ?? 0) + 1,
    };
  }, {});
}

function statCountry(nd: any) {
  return nd.reduce((stat: any, d: any) => {
    return {
      ...stat,
      [d.country]: (stat[d.country] ?? 0) + 1,
    };
  }, {});
}

const columns: GridColDef[] = [
  { field: 'address', headerName: 'Address', flex: 1, minWidth: 250 },
  { field: 'lastUpdated', headerName: 'Last Updated', flex: 1, minWidth: 120 },
  {
    field: 'blocks',
    headerName: 'Height',
    flex: 1,
    minWidth: 60,
    valueGetter: (params) => (params.value > 0 ? params.value : 'N/A'),
  },
  { field: 'a1d', headerName: '1 day', flex: 1, minWidth: 70 },
  { field: 'a7d', headerName: '7 days', flex: 1, minWidth: 70 },
  { field: 'a30d', headerName: '30 days', flex: 1, minWidth: 70 },
  { field: 'city', headerName: 'City', flex: 1, minWidth: 100 },
  { field: 'country', headerName: 'Country', flex: 1, minWidth: 100 },
  { field: 'organization', headerName: 'Organization', flex: 1, minWidth: 120 },
  { field: 'subVersion', headerName: 'Version', flex: 1, minWidth: 200 },
];

export function ActiveMiners() {
  const networkQuery = useNetwork();
  return (
    <QueryProcessor
      query={networkQuery}
      dataValidator={(data: any) => data.type === 'network'}
      renderer={(data: any) => {
        const nd = formatNetworkData(data);
        return <DataGrid autoHeight rows={nd} columns={columns} pageSizeOptions={[10]} />;
      }}
    />
  );
}

export function ActiveSeeds() {
  const networkQuery = useNetworkSeeds();
  return (
    <QueryProcessor
      query={networkQuery}
      dataValidator={(data: any) => data.type === 'network'}
      renderer={(data: any) => {
        const nd = formatNetworkData(data);
        return <DataGrid autoHeight rows={nd} columns={columns} pageSizeOptions={[10]} />;
      }}
    />
  );
}

export function NetworkStatistics() {
  const networkQuery = useNetwork();
  return (
    <QueryProcessor
      query={networkQuery}
      dataValidator={(data: any) => data.type === 'network'}
      renderer={(data: any) => {
        const nd = formatNetworkData(data);
        return <JSONTextField value={{ city: statCity(nd), country: statCountry(nd) }} />;
      }}
    />
  );
}