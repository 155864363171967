import axios from 'axios';
import { useQuery } from 'react-query';
import { environment } from '../config/environment';

export function fetchCoinbase() {
  return axios.get(`${environment.apiBase}/coinbase`).then((res) => res.data);
}

export function useCoinbase() {
  return useQuery('coinbase', () => fetchCoinbase(), {
    retry: false,
    staleTime: 300 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}