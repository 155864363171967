import axios from 'axios';
import { useQuery } from 'react-query';
import { environment } from '../config/environment';

export const useAddress = (address: string, options?: any) => {
  return useQuery(
    ['address', address],
    () => axios.get(`${environment.apiBase}/address/${address}`).then((res) => res.data),
    {
      staleTime: 30000,
      ...options,
    }
  );
};

export default useAddress;
