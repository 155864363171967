import { KeyedObject } from '../abelcommon';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { environment } from '../config/environment';

export function fetchBlocks(start: number, count: number) {
  return axios
    .get(`${environment.apiBase}/blocks?start=${start}&count=${count}`)
    .then((res) => res.data);
}

export function useBlocks(start: number, count: number, options?: KeyedObject) {
  const queryClient = useQueryClient();
  return useQuery(['blocks', start, count], () => fetchBlocks(start, count), {
    retry: false,
    staleTime: 1000 * 300,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      // Update block
      Object.keys(data.payload).forEach((i) => {
        const r = { type: 'block', payload: data.payload[i]};
        queryClient.setQueryData(['block', data.payload[i].height], r);
        queryClient.setQueryData(['block', '0x' + data.payload[i].hash], r);
      });
    },
    ...options,
  });
}

export default useBlocks;
