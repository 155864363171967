import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  CardContent,
  CardActions,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Link,
  Stack,
} from '@mui/material';
import MainCard from '../../components/MainCard';
import { TextAvatar, TextLoading, TextRequestFailed } from './misc';
import useLatestBlocks from '../../queries/useLatestBlocks';
import TimeAgo from '../../components/TimeAgo';

const LatestBlocksContent = () => {
  const latestBlocksQuery = useLatestBlocks();
  if (latestBlocksQuery.isLoading) {
    return TextLoading;
  }
  if (latestBlocksQuery.isError) {
    return TextRequestFailed;
  }

  const blocks = latestBlocksQuery.data.payload;
  const blockInfos: any[] = [];
  for (let prev_time = blocks[0].time, i = 0; i < blocks.length; i++) {
    blockInfos.push({ duration: blocks[i].time - prev_time });
    prev_time = blocks[i].time;
  }

  return (
    <TableContainer>
      <Table sx={{ '& .MuiTableCell-root': { px: 0, py: 1 } }}>
        <TableBody>
          {Array.from(Array(blocks.length).keys())
            .reverse()
            .map((i) => {
              if (i === 0) {
                // Ignore the first block as we don't have its duration.
                return null;
              }
              const bk = blocks[i];
              return (
                <TableRow key={i}>
                  <TableCell align="left" sx={{ width: 40 }}>
                    <TextAvatar text="BK" square />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ pl: 1 }}>
                      <Link component={RouterLink} to={`/block/${bk.height}`}>
                        {bk.height}
                      </Link>
                    </Typography>
                    <Typography variant="body2" sx={{ pl: 1, fontSize: '0.8em' }}>
                      <TimeAgo date={bk.time * 1000} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      <Link component={RouterLink} to={`/block/0x${bk.hash}`}>
                        0x{blocks[i].hash.substring(8, 20)}...
                      </Link>
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.8em' }}>
                      <Link component={RouterLink} to={`/txs?block=${bk.height}`}>
                        {blocks[i].tx.length} txns
                      </Link>{' '}
                      in {blockInfos[i].duration} seconds
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      direction={'row'}
                      sx={{
                        ml: 'auto',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant={'body2'}>🏆 {blocks[i].totalfee} ABEL</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const LatestBlocks = () => {
  return (
    <MainCard title="Latest Blocks">
      <CardContent sx={{ height: 380, maxHeight: 380, overflow: 'auto' }}>
        <LatestBlocksContent />
      </CardContent>
      <Divider />
      <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button variant="contained" href="/blocks" size="small" sx={{ width: 1, height: 24, textTransform: 'none' }}>
          View all blocks
        </Button>
      </CardActions>
    </MainCard>
  );
};

export default LatestBlocks;
