import React from 'react';
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { SmartLink } from '../../abelcommon';
import { IconLink, NavItem, useConfig } from '../config';
import { SmartNode, DiscordIcon, MediumIcon } from '../components';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PeopleIcon from '@mui/icons-material/People';

const CommunityIconLink = ({ icon, title, to }: IconLink) => {
  let tooltip = title;
  let renderedIcon: React.ReactNode;
  if (typeof icon === 'string') {
    tooltip = tooltip ?? icon;
    let CommunityIcon: any;
    if (icon === 'Email') {
      CommunityIcon = EmailIcon;
    } else if (icon === 'Twitter') {
      CommunityIcon = TwitterIcon;
    } else if (icon === 'Facebook') {
      CommunityIcon = FacebookIcon;
    } else if (icon === 'Telegram') {
      CommunityIcon = TelegramIcon;
    } else if (icon === 'Discord') {
      CommunityIcon = DiscordIcon;
    } else if (icon === 'LinkedIn') {
      CommunityIcon = LinkedInIcon;
    } else if (icon === 'Medium') {
      CommunityIcon = MediumIcon;
    } else {
      CommunityIcon = PeopleIcon;
    }
    renderedIcon = (
      <CommunityIcon width={'20px'} height={'20px'} fill={'currentColor'} />
    );
  } else {
    renderedIcon = <SmartNode value={icon} />;
  }

  return (
    <Tooltip title={tooltip ?? to ?? ''}>
      <IconButton
        component={SmartLink}
        to={to}
        sx={{ width: '36px', height: '36px', mr: 1, mt: 1 }}
      >
        {renderedIcon}
      </IconButton>
    </Tooltip>
  );
};

type FooterSitemapRegularItemsProps = {
  idx: number;
  d: NavItem;
};

const FooterSitemapRegularItems = ({
  idx,
  d,
}: FooterSitemapRegularItemsProps) => {
  return (
    <Grid item key={'sitemap-group-' + idx} xs={6} sm={3}>
      <List sx={{ pl: 4, '& a': { textDecoration: 'none' } }} disablePadding>
        <ListItem key={'sitemap-group-title-' + idx} disableGutters>
          <Typography variant={'h6'}>{d.title}</Typography>
        </ListItem>
        <Divider />
        {Array.isArray(d.content) &&
          d.content.map((dd, i) => (
            <ListItem
              key={'sitemap-link-' + idx + '-' + i}
              disableGutters
              disabled={dd.disabled}
            >
              <SmartLink to={dd.disabled ? '#' : dd.to} target={dd.target}>
                <Typography
                  variant={'body2'}
                  sx={{
                    color: 'text.secondary',
                    ':hover': { color: 'text.primary' },
                  }}
                >
                  {dd.title}
                </Typography>
              </SmartLink>
            </ListItem>
          ))}
      </List>
    </Grid>
  );
};

type FooterSitemapCommunityItemsProps = {
  title?: string;
  links: IconLink[];
  doubleWidth?: boolean;
};

const FooterSitemapCommunityItems = ({
  title = 'Community',
  links,
  doubleWidth = false,
}: FooterSitemapCommunityItemsProps) => {
  const wm = doubleWidth ? 2 : 1;
  return (
    <Grid item key={'sitemap-community'} xs={6 * wm} sm={3 * wm}>
      <List sx={{ pl: 4, '& a': { textDecoration: 'none' } }} disablePadding>
        <ListItem key={'sitemap-community-title'} disableGutters>
          <Typography variant={'h6'}>{title}</Typography>
        </ListItem>
        <Divider />
        {links && (
          <Stack direction="row" sx={{ pt: 1, flexWrap: 'wrap' }}>
            {links.map((d, i) => (
              <CommunityIconLink key={i} {...d} />
            ))}
          </Stack>
        )}
      </List>
    </Grid>
  );
};

export const FooterSitemap = () => {
  const c = useConfig();
  return (
    <Grid id={'footer-sitemap'} container rowSpacing={2}>
      {c.footer.sitemap.regular.map((d, i) => (
        <FooterSitemapRegularItems key={i} idx={i} d={d} />
      ))}
      {c.footer.sitemap.community && (
        <FooterSitemapCommunityItems
          doubleWidth={c.footer.sitemap.regular.length % 2 === 0}
          {...c.footer.sitemap.community}
        />
      )}
    </Grid>
  );
};

export default FooterSitemap;
