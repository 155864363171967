import React from 'react';
import {
  SxProps,
  Breakpoint,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PopupState } from 'material-ui-popup-state/hooks';
import { Theme } from '@mui/material/styles';
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import { ReactNodeMaker } from './components';

export type NavItem = {
  title?: string | React.ReactNode | null;
  to?: string;
  target?: '_blank' | '_self';
  content?: 'divider' | NavItem[] | React.ReactNode | null;
  disabled?: boolean;
};

export type BuiltinIconName =
  | 'Twitter'
  | 'Facebook'
  | 'Telegram'
  | 'Discord'
  | 'LinkedIn'
  | 'Medium'
  | 'Email';

export type IconLink = {
  icon: BuiltinIconName | React.ReactNode | ReactNodeMaker;
  title?: string;
  to?: string;
};

export type NavConfig = {
  regular: NavItem[];
  more?: {
    title?: string;
    render: (popupState?: PopupState) => React.ReactNode;
  };
  settings?: {
    icon?: React.ReactNode | ReactNodeMaker;
    render: (popupState?: PopupState) => React.ReactNode;
  };
};

export type HeaderConfig = {
  barHeight: {
    full: number | string;
    mini: number | string;
  };
  miniBarLinks: string[];
  banner: {
    title: React.ReactNode | ReactNodeMaker | string | null;
    subtitle: React.ReactNode | ReactNodeMaker | string | null;
  };
  searchBox?: React.ReactNode;
};

export type FooterConfig = {
  barHeight: {
    full: number | string;
    mini: number | string;
  };
  topbar: {
    icon?: React.ReactNode | ReactNodeMaker | null;
    title: React.ReactNode | ReactNodeMaker | string | null;
    subtitle: React.ReactNode | ReactNodeMaker | string | null;
  };
  banner: {
    icon?: React.ReactNode | ReactNodeMaker | string | null;
    title?: React.ReactNode | ReactNodeMaker | string | null;
    text?: React.ReactNode | ReactNodeMaker | string | null;
    actions?: React.ReactNode | ReactNodeMaker | null;
  };
  sitemap: {
    regular: NavItem[];
    community?: {
      title?: string;
      links: IconLink[];
    };
  };
};

export type MainConfig = {
  sxWrapperForHome?: SxProps | ((theme: Theme) => SxProps);
  errorFallback?: any;
};

export type LayoutConfig = {
  maxWidth: false | Breakpoint | undefined;
  nav: NavConfig;
  header: HeaderConfig;
  footer: FooterConfig;
  main?: MainConfig;
};

export const defaultLayoutConfig: LayoutConfig = {
  maxWidth: 'md',
  nav: {
    regular: [
      { title: 'Home', to: '/' },
      { title: null, content: 'divider' },
      {
        title: 'Blockchain',
        content: [
          { title: 'View Blocks', to: '/blocks' },
          { title: 'View Transactions', to: '/txs' },
          { title: 'View Addresses', to: '/addresses' },
        ],
      },
      {
        title: 'Resources',
        content: [
          { title: 'Charts', to: '/charts' },
          { title: 'Statistics', to: '/statistics' },
          { title: null, content: 'divider' },
          { title: 'Downloads', to: '/downloads' },
          { title: 'Developer APIs', to: '/developer-apis' },
        ],
      },
    ],
    more: {
      title: 'Others',
      render: () => <Typography>Do you want more?</Typography>,
    },
    settings: {
      render: () => <Typography>Here are settings.</Typography>,
    },
  },
  header: {
    barHeight: { full: '96px', mini: '' },
    miniBarLinks: ['/', '/home'],
    banner: {
      title: 'BlockScan',
      subtitle: 'A Blockchain Explorer',
    },
    searchBox: null,
  },
  footer: {
    barHeight: { full: '', mini: '48px' },
    topbar: {
      icon: <CopyrightRoundedIcon fontSize={'small'} />,
      title: 'Powered by A Crypto Foundation',
      subtitle: () => 'BlockScan © ' + new Date().getFullYear(),
    },
    sitemap: {
      regular: [
        {
          title: 'Blockchain',
          content: [
            { title: 'Blocks', to: '/blocks' },
            { title: 'Transactions', to: '/txs' },
            { title: 'Addresses', to: '/addresses' },
          ],
        },
        {
          title: 'Resources',
          content: [
            { title: 'Charts', to: '/charts' },
            { title: 'Statistics', to: '/statistics' },
            { title: 'Downloads', to: '/downloads' },
            { title: 'Developer APIs', to: '/developer-apis' },
          ],
        },
      ],
      community: {
        links: [
          { icon: 'Twitter', to: 'https://twitter.com' },
          { icon: 'Facebook', to: 'https://www.facebook.com' },
          { icon: 'Telegram', to: 'https://www.telegram.org' },
          { icon: 'Discord', to: 'https://discord.gg' },
          { icon: 'LinkedIn', to: 'https://www.linkedin.com' },
          { icon: 'Email', to: 'mailto:contact@mycompany.com' },
        ],
      },
    },
    banner: {
      icon: <LightbulbCircleIcon />,
      title: 'BlockScan',
      text: 'BlockScan is a Block Explorer and Analytics Platform for Blockchain, a decentralized smart contracts platform.',
      actions: (
        <FormControlLabel
          control={<Switch color={'primary'} />}
          label={'Dark Mode'}
          labelPlacement={'end'}
        />
      ),
    },
  },
  main: {
    sxWrapperForHome: (theme: Theme) => {
      const sxLight = {
        background:
          'linear-gradient(180deg, #333 0%, #333 300px, #ddd 300px, #ddd 100%);',
      };
      const sxDark = {
        background:
          'linear-gradient(180deg, #000 0%, #000 300px, #333 300px, #333 100%);',
      };
      return theme.palette.mode === 'light' ? sxLight : sxDark;
    },
  },
};

export const LayoutConfigContext =
  React.createContext<LayoutConfig>(defaultLayoutConfig);

const updateConfig = (config: LayoutConfig) => {
  // Calc header.barHeight.mini
  const full = config.header.barHeight.full;
  config.header.barHeight.mini =
    typeof full === 'number' ? full / 2 : `calc(${full} / 2)`;
  return config;
};

export type LayoutConfigProviderProps = {
  children?: React.ReactNode;
  config?: LayoutConfig;
};

export const LayoutConfigProvider = ({
  children,
  config,
}: LayoutConfigProviderProps) => {
  return (
    <LayoutConfigContext.Provider
      value={updateConfig(config ?? defaultLayoutConfig)}
    >
      {children}
    </LayoutConfigContext.Provider>
  );
};

export const useConfig = () => {
  return React.useContext(LayoutConfigContext);
};

export const isMiniBar = (c: LayoutConfig) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return c.header.miniBarLinks.includes(useLocation().pathname);
};
