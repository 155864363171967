import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, CardContent, Chip, Stack } from '@mui/material';
import { SmartLink, formatDurationAbbr, formatRFC7231 } from '../abelcommon';
import QueryProcessor from '../components/QueryProcessor';
import { useTx, useSummary } from '../queries';
import { TabbedMainCard } from '../components/MainCard';
import { JSONTextField } from '../components/JSONTextField';
import { RichList, RichListItem } from '../components/RichList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { addressPrefixFromFingerprint } from './utils';

function useTxWithPrecheck(txid?: string) {
  if (!txid || !txid.startsWith('0x') || txid.length !== 66) {
    throw new Error('Illegal txid.');
  }
  return useTx(txid);
}

function TxOverview({ d }: { d: any }) {
  const [VinFingerprints, setVinFingerprints] = React.useState<string[]>([]);
  const [VoutFingerprints, setVoutFingerprints] = React.useState<string[]>([]);

  const summaryQuery = useSummary();
  const latestBlock = summaryQuery.isSuccess ? summaryQuery.data.payload.latestBlock : null;

  const ts = new Date();
  const dts = d.time * 1000;

  React.useEffect(() => {
    if (d.txtype === 'transfer') {
      setVinFingerprints(
        d.vin.map((vin: any) => vin.prevutxoring.outpoints.map((outpoint: any) => outpoint.fingerprint)).flat()
      );
      setVoutFingerprints(d.vout.map((vout: any) => vout.scriptinfo.fingerprint));
    } else {
      setVoutFingerprints([d.vout[0].scriptinfo.fingerprint]);
    }
  }, [d]);

  return (
    <RichList sx={{ width: 1, mt: -2 }}>
      <RichListItem
        title={'Transaction Hash'}
        help={
          'A TxHash or transaction hash is a unique 66-character identifier\
           that is generated whenever a transaction is executed.'
        }
      >
        <Typography variant={'body2'}>0x{d.hash}</Typography>
      </RichListItem>

      <RichListItem title={'Status'} help={'The status of the transaction.'}>
        <Chip icon={<CheckCircleIcon />} label={'Success'} color={'primary'} size={'small'} />
      </RichListItem>

      <RichListItem
        title={'Block Height'}
        help={
          'Number of the block in which the transaction is recorded. Block confirmations\
           indicate how many blocks have been added since the transaction was mined.'
        }
      >
        <Typography component={'span'} variant={'body1'}>
          <SmartLink to={`/block/0x${d.blockhash}`}>{d.blockheight}</SmartLink>
        </Typography>

        {latestBlock && (
          <Typography component={'span'} variant={'body2'} sx={{ pr: 1 }}>
            {` (${latestBlock.height - d.blockheight} confirmations)`}
          </Typography>
        )}
      </RichListItem>

      <RichListItem title={'Block Hash'} help={'The hash of the block header of the block recording this transaction.'}>
        <Typography variant={'body2'}>
          <SmartLink to={`/block/0x${d.blockhash}`}>0x{d.blockhash}</SmartLink>
        </Typography>
      </RichListItem>

      <RichListItem title={'Timestamp'} help={'The date and time at which a transaction is mined.'}>
        <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={0.5}>
          <AccessTimeIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
          <Typography>{`${formatDurationAbbr(dts, ts)} ago (${formatRFC7231(dts).substring(5)})`}</Typography>
        </Stack>
      </RichListItem>

      <RichListItem title={'Type'} help={'The type of the transaction (coinbase or transfer).'}>
        {d.txtype}
      </RichListItem>

      <RichListItem title={'From'} help={'The sending party of the transaction.'}>
        {d.txtype === 'coinbase' ? (
          <Typography variant={'body2'} fontFamily={'monospace'}>
            0x0 (Null Address)
          </Typography>
        ) : (
          <Stack direction={'column'} sx={{ alignItems: 'left' }} spacing={0.5}>
            {VinFingerprints.map((fingerprint: any, idx: number) => (
              <Typography key={idx} variant={'body2'} fontFamily={'monospace'}>
                <SmartLink to={`/address/0xabe1${fingerprint}`}>0x{addressPrefixFromFingerprint(fingerprint)}...</SmartLink>
              </Typography>
            ))}
          </Stack>
        )}
      </RichListItem>

      <RichListItem title={'To'} help={'The receiving party of the transaction.'}>
        <Stack direction={'column'} sx={{ alignItems: 'left' }} spacing={0.5}>
          {VoutFingerprints.map((fingerprint: any, idx: number) => (
            <Typography key={idx} variant={'body2'} fontFamily={'monospace'}>
              <SmartLink to={`/address/0xabe1${fingerprint}`}>0x{addressPrefixFromFingerprint(fingerprint)}...</SmartLink>
            </Typography>
          ))}
        </Stack>
      </RichListItem>

      <RichListItem
        title={'Value'}
        help={
          'For coinbase transaction, the value is the total reward of the containing block. For transfer transaction, the value is the transaction fee.'
        }
      >
        {d.fee} ABEL
      </RichListItem>
    </RichList>
  );
}

export function ViewTx() {
  let { txid } = useParams();
  if (txid && !txid.startsWith('0x')) {
    txid = '0x' + txid;
  }

  return (
    <QueryProcessor
      query={useTxWithPrecheck(txid)}
      dataValidator={(data: any) => data.type === 'tx'}
      renderer={(data: any) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component={'span'} variant={'h2'}>
              Transaction Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TabbedMainCard labels={['Overview', 'Raw Data']}>
              <CardContent>
                <TxOverview d={data.payload} />
              </CardContent>
              <CardContent>
                <JSONTextField value={data} />
              </CardContent>
            </TabbedMainCard>
          </Grid>
        </Grid>
      )}
    />
  );
}

export default ViewTx;
