import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import {
  Grid,
  Typography,
  LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { SmartLink, formatDurationAbbr, formatRFC7231 } from '../abelcommon';
import PaginationCard from '../components/PaginationCard';
import ErrorCard from '../components/ErrorCard';
import QueryProcessor from '../components/QueryProcessor';
import { useSummary, useBlocks } from '../queries';

const isFirstPageState = atom<boolean>({
  key: 'ViewAllBlocks-is-first-page',
  default: true,
});

/* eslint-disable-next-line */
export interface ViewAllBlocksProps {}

export function ViewAllBlocks(props: ViewAllBlocksProps) {
  const [searchParams] = useSearchParams();
  const [latestHeight, setLatestHeight] = React.useState(-1);
  const [isFirstPage, setIsFirstPage] = useRecoilState(isFirstPageState);

  let pageSize = Number(searchParams.get('ps'));
  if (isNaN(pageSize) || pageSize < 10) {
    pageSize = 25;
  }

  let pageNo = Number(searchParams.get('p'));
  if (isNaN(pageNo) || pageNo < 1) {
    pageNo = 1;
  }

  setIsFirstPage(pageNo === 1);

  // To fulfill React's requirements of invoking hooks, we use 'enabled'
  // option in useQuery to do conditional query.
  const summaryQuery = useSummary({
    enabled: isFirstPage || latestHeight < 0,
  });

  // When pageNo is not 1, we do not update latestHeight if it was set previously.
  // As a result, the page won't refresh automatically which keeps a stable view of
  // historical blocks for the (previsouly) calculated range of heights.
  if (summaryQuery.isSuccess) {
    const latestBlockHeight = summaryQuery.data.payload.latestBlock.height;
    if (latestHeight < 0 || (isFirstPage && latestBlockHeight > latestHeight)) {
      setLatestHeight(latestBlockHeight);
    }
  }

  if (latestHeight >= 0) {
    return <ViewBlocks latestHeight={latestHeight} pageSize={pageSize} pageNo={pageNo} />;
  }

  return (
    <QueryProcessor
      query={summaryQuery}
      dataValidator={(data: any) => data.type === 'summary'}
      renderer={(data: any) => {
        return <ViewBlocks latestHeight={data.payload.latestBlock.height} pageSize={pageSize} pageNo={pageNo} />;
      }}
    />
  );
}

interface ViewBlocksProps {
  latestHeight: number;
  pageSize: number;
  pageNo: number;
}

function ViewBlocks({ latestHeight, pageSize, pageNo }: ViewBlocksProps) {
  const heightEnd = latestHeight - (pageNo - 1) * pageSize;
  const heightBegin = Math.max(0, heightEnd - pageSize + 1);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component={'span'} variant={'h2'}>
          Blocks{' '}
        </Typography>
        <Typography component={'span'} variant={'h5'} color={'textSecondary'}>
          #{heightBegin} to #{heightEnd} (Total of {(latestHeight + 1).toLocaleString()} blocks)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaginationCard totalItems={latestHeight + 1} pageSize={pageSize} pageNo={pageNo}>
          <ViewBlocksContent heightBegin={heightBegin} heightEnd={heightEnd} />
        </PaginationCard>
      </Grid>
    </Grid>
  );
}

interface ViewBlocksContentProps {
  heightBegin: number;
  heightEnd: number;
}

function ViewBlocksContent({ heightBegin, heightEnd }: ViewBlocksContentProps) {
  const fetchCount = 100;
  const startForHeightBegin = Math.floor(heightBegin / fetchCount) * fetchCount;
  const startForHeightEnd = Math.floor(heightEnd / fetchCount) * fetchCount;
  const isFirstPage = useRecoilValue(isFirstPageState);

  const queryOptions = {
    staleTime: isFirstPage ? 1000 * 30 : 1000 * 300,
    refetchOnMount: isFirstPage,
    refetchOnWindowFocus: isFirstPage,
  };
  const qs = [
    useBlocks(startForHeightBegin, fetchCount, {
      enabled: startForHeightBegin !== startForHeightEnd,
      ...queryOptions,
    }),
    useBlocks(startForHeightEnd, fetchCount, {
      ...queryOptions,
    }),
  ];

  if (qs.some((q) => q.isLoading) || qs.some((q) => q.isRefetching)) {
    return <LinearProgress color={'warning'} />;
  }

  if (qs.some((q) => q.isError) || qs.some((q) => !q.isSuccess)) {
    return (
      <ErrorCard enableGoBack={true}>
        <Typography variant={'h4'}>Failed fetching blocks data.</Typography>
      </ErrorCard>
    );
  }

  const data =
    startForHeightBegin === startForHeightEnd ? qs[0].data.payload : [...qs[0].data.payload, ...qs[1].data.payload];

  return <BlockList data={data.filter((d: any) => heightBegin <= d.height && d.height <= heightEnd)} />;
}

interface BlockListProps {
  data: any[];
}

function BlockList({ data }: BlockListProps) {
  // Sort by height reversely.
  data.sort((a, b) => b.height - a.height);

  const ts = new Date();

  return (
    <TableContainer sx={{ width: { xs: 'auto', sm: 1 } }}>
      <Table
        sx={{
          width: { xs: 'max-content', sm: 1 },
          tableLayout: 'auto',
          '& .MuiTableCell-root': { px: 1, py: 1 },
        }}
      >
        <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
          <TableRow>
            <TableCell>Block</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Difficulty</TableCell>
            <TableCell align={'right'}>Size</TableCell>
            <TableCell align={'right'}>Full Size</TableCell>
            <TableCell align={'right'}>Txn</TableCell>
            <TableCell align={'right'}>Time</TableCell>
            <TableCell align={'right'}>Reward</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d, i) => {
            const dts = d.time * 1000;
            return (
              <TableRow key={i}>
                <TableCell>
                  <SmartLink to={`/block/${d.height}`}>{d.height}</SmartLink>
                </TableCell>
                <TableCell>{formatDurationAbbr(dts, ts)} ago</TableCell>
                <TableCell>{d.difficulty}</TableCell>
                <TableCell align={'right'}>{d.size.toLocaleString()}</TableCell>
                <TableCell align={'right'}>{d.fullsize.toLocaleString()}</TableCell>
                <TableCell align={'right'}>{d.tx.length}</TableCell>
                <TableCell align={'right'}>{formatRFC7231(dts).substring(5)}</TableCell>
                <TableCell align={'right'}>{d.totalfee} ABEL</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ViewAllBlocks;
