import React from 'react';
import {
  SxProps,
  Stack,
  Paper,
  Button,
  Divider,
  List,
  ListItemButton,
  Box,
  IconButton,
} from '@mui/material';
import {
  bindHover,
  bindPopover,
  PopupState,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { KeyedObject, SmartLink } from '../../abelcommon';
import { useConfig, NavItem } from '../config';
import { SmartNode, ExpandMore } from '../components';
import SettingsIcon from '@mui/icons-material/Settings';
import { Theme } from '@mui/material/styles';

type HeaderNavbarButtonProps = KeyedObject & {
  title: string | React.ReactNode;
  expandMore?: boolean;
};

const HeaderNavbarButton = React.forwardRef(
  (
    { title, expandMore = false, ...others }: HeaderNavbarButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <Button
        ref={ref}
        component={SmartLink}
        sx={{
          ':hover': { borderBottom: 0 },
          borderRadius: 0,
          textTransform: 'none',
          width: 'auto',
          height: 1,
          px: 1.5,
        }}
        {...others}
      >
        <SmartNode
          value={title}
          typographyProps={{ variant: 'h5', color: 'textSecondary' }}
        />
        {expandMore && <ExpandMore />}
      </Button>
    );
  }
);

type HeaderNavbarPopoverProps = {
  children?: React.ReactNode | ((popupState: PopupState) => React.ReactNode);
  name: string;
  title: string | React.ReactNode;
  anchorDirection?: 'left' | 'right';
  sxPaper?: SxProps;
  hideExpandMoreIcon?: boolean;
};

const HeaderNavbarPopover = ({
  children,
  name,
  title,
  anchorDirection = 'left',
  sxPaper = {},
  hideExpandMoreIcon = false,
}: HeaderNavbarPopoverProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'Navbar-Popover-' + name,
  });
  return (
    <>
      {typeof title === 'string' && (
        <HeaderNavbarButton
          title={title}
          expandMore={!hideExpandMoreIcon}
          {...bindHover(popupState)}
        />
      )}
      {typeof title !== 'string' && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', height: 1 }}
          {...bindHover(popupState)}
        >
          {title}
        </Box>
      )}
      <HoverPopover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: anchorDirection }}
        transformOrigin={{ vertical: 'top', horizontal: anchorDirection }}
      >
        <Paper
          sx={{
            borderTop: 1,
            borderRadius: 0,
            borderWidth: 3,
            borderColor: 'primary.main',
            ...sxPaper,
          }}
        >
          {typeof children === 'function' && children(popupState)}
          {typeof children !== 'function' && children}
        </Paper>
      </HoverPopover>
    </>
  );
};

type HeaderNavBarRegularItemProps = {
  name: string;
  d: NavItem;
};

const HeaderNavbarRegularItem = ({ name, d }: HeaderNavBarRegularItemProps) => {
  if (d.content === 'divider') {
    return <Divider orientation={'vertical'} sx={{ height: 0.5 }} />;
  } else if (!d.title) {
    return null;
  } else if (!d.content) {
    return <HeaderNavbarButton title={d.title} to={d.to} />;
  }

  return (
    <HeaderNavbarPopover
      name={name}
      title={d.title}
      sxPaper={{ minWidth: 200 }}
    >
      {(popupState) => {
        if (Array.isArray(d.content)) {
          return (
            <List>
              {d.content.map((dd, i) => {
                const key = 'Navbar-Regular-' + name + '-' + i;
                if (dd.content === 'divider') {
                  return <Divider key={key} sx={{ width: 1 }} />;
                }
                return (
                  <ListItemButton
                    key={key}
                    component={SmartLink}
                    to={dd.to}
                    onClick={() => {
                      popupState.setOpen(false);
                    }}
                    disabled={dd.disabled}
                  >
                    <SmartNode
                      value={dd.title}
                      typographyProps={{
                        variant: 'h6',
                        color: 'textSecondary',
                      }}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          );
        } else {
          return <SmartNode value={d.content} />;
        }
      }}
    </HeaderNavbarPopover>
  );
};

type HeaderNavBarMoreItemProps = {
  title?: string;
  render: (popupState?: PopupState) => React.ReactNode;
};

const HeaderNavbarMoreItem = ({
  title = 'More',
  render,
}: HeaderNavBarMoreItemProps) => {
  return (
    <HeaderNavbarPopover
      name={'more'}
      title={title}
      anchorDirection={'right'}
      sxPaper={{ minWidth: '100vw' }}
    >
      {(popupState) => render(popupState)}
    </HeaderNavbarPopover>
  );
};

type HeaderNavBarSettingsItemProps = {
  icon?: React.ReactNode | ((theme: Theme) => React.ReactNode);
  render: (popupState?: PopupState) => React.ReactNode;
};

const HeaderNavbarSettingsItem = ({
  icon = <SettingsIcon fontSize={'small'} />,
  render,
}: HeaderNavBarSettingsItemProps) => {
  const iconButton = (
    <IconButton
      sx={{
        display: 'flex',
        color: 'primary.main',
        backgroundColor: 'inherit',
        ':hover': {
          color: 'primary.main',
          backgroundColor: 'inherit',
        },
      }}
    >
      <SmartNode value={icon ?? <SettingsIcon fontSize={'small'} />} />
    </IconButton>
  );
  return (
    <HeaderNavbarPopover
      name={'settings'}
      title={iconButton}
      hideExpandMoreIcon={true}
      anchorDirection={'right'}
      sxPaper={{ minWidth: '100px' }}
    >
      {(popupState) => render(popupState)}
    </HeaderNavbarPopover>
  );
};

export const HeaderNavbar = () => {
  const c = useConfig();
  return (
    <Stack
      sx={{
        display: { xs: 'none', sm: 'flex' },
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 1,
        height: c.header.barHeight.mini,
      }}
    >
      {c.nav.regular.map((item, i) => (
        <HeaderNavbarRegularItem
          key={'regular-' + i}
          name={'regular-' + i}
          d={item}
        />
      ))}
      {c.nav.more && (
        <>
          <Divider orientation={'vertical'} sx={{ height: 0.5 }} />
          <HeaderNavbarMoreItem key={'more'} {...c.nav.more} />
        </>
      )}
      {c.nav.settings && (
        <>
          <Divider orientation={'vertical'} sx={{ height: 0.5 }} />
          <HeaderNavbarSettingsItem key={'settings'} {...c.nav.settings} />
        </>
      )}
    </Stack>
  );
};

export default HeaderNavbar;
