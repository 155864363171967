import React from 'react';
import { Box } from '@mui/material';
import { useConfig, isMiniBar } from '../config';

export const HeaderSearch = () => {
  const c = useConfig();
  const miniBar = isMiniBar(c);
  return (
    <Box
      sx={{
        ml: 'auto',
        display: miniBar ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: { xs: 1, sm: 450, md: 600 },
        height: c.header.barHeight.mini,
        mt: { xs: 0, sm: 1 },
        mb: { xs: 1, sm: -1 },
      }}
    >
      {!miniBar && c.header.searchBox}
    </Box>
  );
};

export default HeaderSearch;
