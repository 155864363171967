import axios from 'axios';
import { useQuery } from 'react-query';
import { environment } from '../config/environment';

export const fetchTx = (txid: string) =>
  axios
    .get(`${environment.apiBase}/tx/${txid}`)
    .then((res) => res.data);

export const useTx = (txid: string) => {
  return useQuery(['tx', txid], () => fetchTx(txid), {
    retry: false,
    staleTime: 3600 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useTx;