import { KeyedObject } from '../abelcommon';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { environment } from '../config/environment';

export function fetchTxs(block: number, address: string, txType: string, start?: number, count?: number) {
  const searchParams = new URLSearchParams();
  if (block >= 0) {
    searchParams.append('block', String(block));
  }
  if (address && address.length > 0) {
    searchParams.append('address', address);
  }
  if (txType === 'coinbase' || txType === 'transfer') {
    searchParams.append('type', txType);
  }
  if (start !== undefined) {
    searchParams.append('start', String(start));
  }
  if (count !== undefined) {
    searchParams.append('count', String(count));
  }

  return axios.get(`${environment.apiBase}/txs?${searchParams.toString()}`).then((res) => res.data);
}

export function useTxs(
  block: number,
  address: string,
  txType: string,
  start?: number,
  count?: number,
  options?: KeyedObject
) {
  const queryClient = useQueryClient();
  return useQuery(['txs', block, address, txType, start, count], () => fetchTxs(block, address, txType, start, count), {
    retry: false,
    staleTime: 1000 * 300,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      // Update tx
      Object.keys(data.payload).forEach((i) => {
        const r = { type: 'tx', payload: data.payload[i] };
        queryClient.setQueryData(['tx', '0x' + data.payload[i].txid], r);
      });
    },
    ...options,
  });
}

export default useTxs;
