import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Grid, Typography, CardContent, Tooltip, Button, SxProps } from '@mui/material';
import { SmartLink, formatDurationAbbr, formatRFC7231 } from '../abelcommon';
import { useBlock, useSummary } from '../queries';
import { QueryProcessor } from '../components/QueryProcessor';
import { TabbedMainCard } from '../components/MainCard';
import { JSONTextField } from '../components/JSONTextField';
import { RichList, RichListItem } from '../components/RichList';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const useBlockWithPrecheck = (block_height_or_hash?: string | number) => {
  let k: number | string = block_height_or_hash ?? '';
  if (typeof k === 'string' && !k.startsWith('0x')) {
    k = Number(k);
    if (isNaN(k)) {
      throw new Error('Illegal block height or hash.');
    }
  }
  return useBlock(k);
};

type TinyIconButtonProps = {
  icon: any;
  to: string;
  disabled?: boolean;
  tooltip?: string;
  sx?: SxProps;
};

const TinyIconButton = ({ icon, to, disabled = false, tooltip, sx }: TinyIconButtonProps) => {
  const Icon = icon;
  return (
    <Button
      variant={'outlined'}
      sx={{ minHeight: 16, minWidth: 16, p: 0, ...sx }}
      component={SmartLink}
      to={to}
      disabled={disabled}
    >
      {tooltip && (
        <Tooltip title={tooltip} arrow placement={'top'}>
          <Icon sx={{ fontSize: 10 }} />
        </Tooltip>
      )}
      {!tooltip && <Icon sx={{ fontSize: 10 }} />}
    </Button>
  );
};

const BlockOverview = ({ d }: { d: any }) => {
  const [prevHeight, nextHeight] = [d.height - 1, d.height + 1];
  const prevBlockQuery = useBlock(prevHeight);
  const prevBlock =
    prevBlockQuery.isSuccess && prevBlockQuery.data.type === 'block' ? prevBlockQuery.data.payload : null;
  const summaryQuery = useSummary();
  const latestBlock = summaryQuery.isSuccess ? summaryQuery.data.payload.latestBlock : null;
  const treatAsLatest = !latestBlock || d.height >= latestBlock.height;
  const isGenesisBlock = d.height === 0;

  const ts = new Date();
  const dts = d.time * 1000;
  return (
    <RichList sx={{ width: 1, mt: -2 }}>
      <RichListItem
        title={'Block Height'}
        help={
          'Also known as Block Number. The block height, which indicates the length\
           of the blockchain, increases after the addition of the new block.'
        }
      >
        <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={0}>
          <Typography sx={{ pr: 1, fontWeight: { xs: 'normal', sm: 'bold' } }}>{d.height}</Typography>
          {!treatAsLatest && <Typography sx={{ pr: 1 }}>({latestBlock.height - d.height} confirmations)</Typography>}
          {treatAsLatest && <Typography sx={{ pr: 1 }}>(The latest block for the moment.)</Typography>}
          <TinyIconButton
            icon={ArrowBackIosNewIcon}
            to={`/block/${prevHeight}`}
            disabled={isGenesisBlock}
            tooltip={isGenesisBlock ? 'No earlier block' : `View previous block (height: ${prevHeight})`}
            sx={{ mx: 0.5 }}
          />
          <TinyIconButton
            icon={ArrowForwardIosIcon}
            to={`/block/${nextHeight}`}
            disabled={treatAsLatest}
            tooltip={treatAsLatest ? 'No later block' : `View next block (height: ${nextHeight})`}
            sx={{ mx: 0.5 }}
          />
        </Stack>
      </RichListItem>

      <RichListItem title={'Timestamp'} help={'The date and time at which a block is mined.'}>
        <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={0.5}>
          <AccessTimeIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
          <Typography>{`${formatDurationAbbr(dts, ts)} ago (${formatRFC7231(dts).substring(5)})`}</Typography>
        </Stack>
      </RichListItem>

      <RichListItem title={'Transactions'} help={'The number of transactions in the block.'}>
        <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={1}>
          <Button
            variant={'outlined'}
            sx={{ minHeight: 16, minWidth: 120, py: 0, px: 1, fontSize: 10 }}
            component={SmartLink}
            to={`/txs?block=${d.height}`}
          >
            {`${d.tx.length} transactions`}
          </Button>
          <Typography>in this block</Typography>
        </Stack>
      </RichListItem>

      <RichListItem title={'Coinbase Tx'} help={'The transaction sending mining reward to the miner of this block.'}>
        <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
          <SmartLink to={`/tx/0x${d.tx[0]}`}>{`0x${d.tx[0]}`}</SmartLink>
          {prevBlock && ` in ${(d.time - prevBlock.time).toLocaleString()} secs`}
        </Typography>
      </RichListItem>

      <RichListItem
        title={'Block Reward'}
        help={'The total reward of this block including miner reward and all transaction fees.'}
      >
        <Typography variant={'body2'}>🏆 {d.totalfee} ABEL</Typography>
      </RichListItem>

      <RichListItem
        title={'Difficulty'}
        help={
          'The amount of effort required to mine a new block.\
               The difficulty algorithm may adjust according to time.'
        }
      >
        <Typography variant={'body2'}>{d.difficulty.toLocaleString()}</Typography>
      </RichListItem>

      <RichListItem title={'Size'} help={'The block size.'}>
        <Typography variant={'body2'}>{d.size.toLocaleString()} bytes</Typography>
      </RichListItem>

      <RichListItem title={'Full Size'} help={'The full block size.'}>
        <Typography variant={'body2'}>{d.fullsize.toLocaleString()} bytes</Typography>
      </RichListItem>

      <RichListItem title={'Version'} help={'The version of blockchain protocol in Hex format.'}>
        <Typography variant={'body2'}>0x{d.versionHex}</Typography>
      </RichListItem>

      <RichListItem
        title={'Nonce'}
        help={'Block nonce is a value used during mining to demonstrate proof of work for a block.'}
      >
        <Typography variant={'body2'}>{d.nonce}</Typography>
      </RichListItem>

      <RichListItem title={'Bits'} help={'The bits value of the block.'}>
        <Typography variant={'body2'}>{d.bits}</Typography>
      </RichListItem>

      <RichListItem
        title={'Merkle Root'}
        help={'A Merkle root is the hash of all the hashes of all the transactions that are part of a block.'}
      >
        <Typography variant={'body2'}>0x{d.merkleroot}</Typography>
      </RichListItem>

      <RichListItem title={'Hash'} help={'The hash of the block header of the current block.'}>
        <Typography variant={'body2'}>0x{d.hash}</Typography>
      </RichListItem>

      <RichListItem title={'Previous Block Hash'} help={'The hash of the block header of the previous block.'}>
        <Typography variant={'body2'}>
          <SmartLink to={`/block/0x${d.previousblockhash}`}>0x{d.previousblockhash}</SmartLink>
        </Typography>
      </RichListItem>

      <RichListItem title={'Next Block Hash'} help={'The hash of the block header of the next block.'}>
        <Typography variant={'body2'}>
          {d.nextblockhash && <SmartLink to={`/block/0x${d.nextblockhash}`}>0x{d.nextblockhash}</SmartLink>}
        </Typography>
      </RichListItem>
    </RichList>
  );
};

const ViewBlock = () => {
  let { block_height_or_hash } = useParams();
  if (block_height_or_hash && block_height_or_hash.length > 20 && !block_height_or_hash.startsWith('0x')) {
    block_height_or_hash = '0x' + block_height_or_hash;
  }

  return (
    <QueryProcessor
      query={useBlockWithPrecheck(block_height_or_hash)}
      dataValidator={(data: any) => data.type === 'block'}
      renderer={(data: any) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component={'span'} variant={'h2'}>
              Block{' '}
            </Typography>
            <Typography component={'span'} variant={'h5'} color={'textSecondary'}>
              #{data.payload.height}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TabbedMainCard labels={['Overview', 'Raw Data']}>
              <CardContent>
                <BlockOverview d={data.payload} />
              </CardContent>
              <CardContent>
                <JSONTextField value={data.payload} />
              </CardContent>
            </TabbedMainCard>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ViewBlock;
