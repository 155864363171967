import React from 'react';
import { Button } from '@mui/material';
import { KeyedObject } from '../abelcommon';

export type CooldownButtonProps = KeyedObject & {
  millis?: number | number[];
};

export const CooldownButton = React.forwardRef(
  (
    { millis = 3000, ...others }: CooldownButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const [intervals, setIntervals] = React.useState(
      typeof millis == 'number' ? [0, millis] : millis
    );
    const [now, setNow] = React.useState(new Date().getTime());
    const [expiry, setExpiry] = React.useState(now + intervals[0]);
    const remains = expiry - now;

    React.useEffect(() => {
      const task = setInterval(() => setNow(new Date().getTime()), 500);
      return () => clearInterval(task);
    }, []);

    const onClick = () => {
      let interval = intervals[0];
      if (intervals.length > 1) {
        interval = intervals[1];
        setIntervals(intervals.slice(1));
      }
      setExpiry(new Date().getTime() + interval);
      return others['onClick']();
    };

    return (
      <Button ref={ref} {...others} disabled={remains > 0} onClick={onClick}>
        {others['children']}
        {remains > 0 && `(${Math.floor(Math.max(0, remains - 300) / 1000)})`}
      </Button>
    );
  }
);

export default CooldownButton;
