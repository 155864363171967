import React from 'react';
import {
  FormGroup,
  Button,
  Input,
  Box,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { KeyedObject } from '../abelcommon';

const keywordsState = atom({
  key: 'search-box-keywords',
  default: '',
});

export type SearchBoxProps = {
  placeholder?: string;
  searchHandler?: (keywords: string) => void;
  sx?: SxProps<Theme>;
  sxInput?: SxProps<Theme>;
  sxButton?: SxProps<Theme>;
  sizeFactor?: number;
};

export const SearchBox = ({
  placeholder = 'Please input search keywords.',
  searchHandler = () => {},
  sx = {},
  sxInput = {},
  sxButton = {},
  sizeFactor = 0.7,
}: SearchBoxProps) => {
  const theme = useTheme();
  const [keywords, setKeywords] = useRecoilState(keywordsState);

  const onButtonClick = (e: any) => {
    setKeywords('');
    searchHandler(keywords);
  };

  const buttonSize = `${1 + sizeFactor * 2}em`;
  const iconSize = `${0.5 + sizeFactor}em`;

  return (
    <Box
      component={'form'}
      sx={{ width: 1, ...theme.typography.body1, ...sx, height: buttonSize }}
    >
      <FormGroup row sx={{ width: 1, height: 1, alignItems: 'center' }}>
        <Box sx={{ width: `calc(100% - ${buttonSize})`, height: 1 }}>
          <Input
            fullWidth
            placeholder={placeholder}
            disableUnderline
            sx={{
              height: 1,
              px: `${sizeFactor}em`,
              font: 'inherit',
              border: 1,
              borderRight: 0,
              borderTopLeftRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: theme.shape.borderRadius,
              borderColor: 'primary.main',
              backgroundColor: 'inherit',
              color: 'text.secondary',
              '& :hover': {
                color: 'text.primary',
              },
              ...sxInput,
            }}
            value={keywords}
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onButtonClick(e);
                e.preventDefault();
              }
            }}
          />
        </Box>
        <Button
          variant={'contained'}
          sx={{
            minWidth: 0,
            minHeight: 0,
            px: 0,
            font: 'inherit',
            width: buttonSize,
            height: buttonSize,
            border: 1,
            borderColor: 'primary.main',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            boxShadow: 0,
            '&.MuiButtonBase-root:hover': {
              backgroundColor: 'primary.main',
              boxShadow: 0,
            },
            ...sxButton,
          }}
          onClick={onButtonClick}
        >
          <SearchIcon
            sx={{ font: 'inherit', width: iconSize, height: iconSize }}
          />
        </Button>
      </FormGroup>
    </Box>
  );
};

export const AppSearchBox = (props: KeyedObject) => {
  const navigate = useNavigate();
  const search = (keywords: string) => {
    const trimmedKeywords = keywords.trim();
    if (!trimmedKeywords) {
      return;
    }
    navigate(`/search?keywords=${trimmedKeywords}`);
  };

  return (
    <SearchBox
      placeholder={'Search by Block / Tx Hash / Address'}
      searchHandler={search}
      {...props}
    />
  );
};

export default SearchBox;
