import React from 'react';
import { defaultLayoutConfig, LayoutConfig, useConfig } from '../config';
import { KeyedObject } from '../../abelcommon';
import deepmerge from './deepmerge';

export type HeaderState = {
  drawerOpen: boolean;
  drawerExpanded: number | string | null;
};

export const initialHeaderState = {
  drawerOpen: false,
  drawerExpanded: null,
};

export type HeaderContextType = {
  c: LayoutConfig;
  s: HeaderState;
  m: (update: KeyedObject) => void;
};

export const HeaderContext = React.createContext<HeaderContextType>({
  c: defaultLayoutConfig,
  s: initialHeaderState,
  m: () => {},
});

export type HeaderContextProviderProps = {
  children?: React.ReactNode;
};

export const HeaderContextProvider = ({
  children,
}: HeaderContextProviderProps) => {
  const [s, setS] = React.useState<HeaderState>(initialHeaderState);
  const c = useConfig();
  const m = (update: KeyedObject) =>
    setS(deepmerge.withOptions({ mergeArrays: false }, s, update));
  const headerContext = { c, s, m };
  return (
    <HeaderContext.Provider value={headerContext}>
      {children}
    </HeaderContext.Provider>
  );
};

export const HeaderContextConsumer = HeaderContext.Consumer;

export const useHeaderContext = () => {
  return React.useContext(HeaderContext);
};
