import React from 'react';
import { Grid } from '@mui/material';
import SearchBar from './SearchBar';
import ImportantNumbers from './ImportantNumbers';
import LatestBlocks from './LatestBlocks';
import LatestTransactions from './LatestTransactions';

const Dashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchBar />
      </Grid>
      <Grid item xs={12}>
        <ImportantNumbers />
      </Grid>
      <Grid item xs={12} md={6}>
        <LatestBlocks />
      </Grid>
      <Grid item xs={12} md={6}>
        <LatestTransactions />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
