import React from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { LinearProgress, Typography } from '@mui/material';
import ErrorCard from '../components/ErrorCard';
import { useSearchResult } from '../queries';
import { QueryProcessor } from '../components/QueryProcessor';
import { useBlock, useTx } from '../queries';

export const SearchNotFoundCard = ({ keywords }: { keywords: string | null }) => {
  return (
    <ErrorCard title={'Search not found'} enableGoBack={true} sx={{ width: 1 }}>
      <Typography gutterBottom>Sorry! The following string you entered is invalid for search:</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>{keywords}</Typography>
    </ErrorCard>
  );
};

export const RemoteSearch = () => {
  const [searchParams] = useSearchParams();
  const keywords = searchParams.get('keywords') ?? '';

  const keyword = keywords.trim().split(' ')[0];
  const enableQuery = keyword.length > 20 || !isNaN(Number(keyword));
  const searchQuery = useSearchResult(keyword, { enabled: enableQuery });
  if (!enableQuery) {
    return <SearchNotFoundCard keywords={keywords} />;
  }

  return (
    <QueryProcessor
      query={searchQuery}
      dataValidator={(d: any) => d.type === 'search-result'}
      renderer={(d: any) => {
        const { blocks } = d.payload.matches;
        if (blocks.length === 0) {
          return <SearchNotFoundCard keywords={keywords} />;
        }
        return <Navigate to={`/block/${blocks[0].height}`} replace />;
      }}
    />
  );
};

export function LocalSearch() {
  const [searchParams] = useSearchParams();
  const keywords = searchParams.get('keywords') ?? '';
  const keyword = keywords.trim().split(' ')[0];

  if (!isNaN(Number(keyword))) {
    const blockHeight = Number(keyword);
    if (blockHeight >= 0 && blockHeight < 2000000) {
      return <Navigate to={`/block/${blockHeight}`} replace />;
    }
  }

  let hexHash = keyword.toLowerCase();
  hexHash = hexHash.startsWith('0x') ? hexHash : '0x' + hexHash;
  if (hexHash.startsWith('0xabe')) {
    return <Navigate to={`/address/${hexHash}`} replace />;
  }

  return <BlockOrTx hexHash={hexHash} />;
}

function BlockOrTx({ hexHash }: { hexHash: string }) {
  const blockQuery = useBlock(hexHash);
  const txQuery = useTx(hexHash);

  if (blockQuery.isSuccess) {
    return <Navigate to={`/block/${hexHash}`} replace />;
  }

  if (txQuery.isSuccess) {
    return <Navigate to={`/tx/${hexHash}`} replace />;
  }

  if (blockQuery.isError && txQuery.isError) {
    return <SearchNotFoundCard keywords={hexHash} />;
  }

  return <LinearProgress color={'warning'} />;
}

// TODO: When it becomes necessary, we should replace LocalSearch
// with (a re-implemented) RemoteSearch.
export default LocalSearch;
