import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { environment } from '../config/environment';

export const useLatestBlocks = () => {
  const queryClient = useQueryClient();
  return useQuery(
    'latest-blocks',
    () => axios.get(`${environment.apiBase}/blocks`).then((res) => res.data),
    {
      retry: false,
      staleTime: 1000 * 60,
      refetchInterval: 1000 * 60,
      onSuccess: (data) => {
        // Update block
        Object.keys(data.payload).forEach((i) => {
          const r = { type: 'block', payload: data.payload[i] };
          queryClient.setQueryData(['block', data.payload[i].height], r);
          queryClient.setQueryData(['block', '0x' + data.payload[i].hash], r);
        });

        // Update summary
        const latestBlock = data.payload.at(-1);
        const summary: any = queryClient.getQueryData('summary');
        if (
          summary &&
          latestBlock &&
          latestBlock.height > summary.payload.latestBlock.height
        ) {
          queryClient.setQueryData('summary', {
            payload: { ...summary.payload, latestBlock: latestBlock },
          });
        }
      },
    }
  );
};

export default useLatestBlocks;
