import React from 'react';
import { Stack } from '@mui/material';
import { LayoutConfigProvider, useConfig } from './config';
import Header from './Header';
import Footer, { FooterReserved } from './Footer';
import Main from './Main';
import { LayoutConfig } from './config';

export type LayoutProps = {
  children?: React.ReactNode;
  config?: LayoutConfig;
};

export const AbelScanLayout = ({ children, config }: LayoutProps) => {
  return (
    <LayoutConfigProvider config={config}>
      <Stack
        id={'wrapper-header-and-main'}
        sx={{ minHeight: { xs: '-webkit-fill-available', sm: '100vh' } }}
      >
        <Header />
        <Main>{children}</Main>
        <FooterReserved />
      </Stack>
      <Footer />
    </LayoutConfigProvider>
  );
};

export const useLayoutConfig = useConfig;

export default AbelScanLayout;
