import React from 'react';
import { Stack, Box } from '@mui/material';
import { useConfig } from '../config';
import { SmartNode } from '../components';

export const FooterBanner = () => {
  const c = useConfig();
  return (
    <Stack id={'footer-banner'} sx={{ mx: 3 }}>
      <Stack direction={'row'} sx={{ alignItems: 'center', mb: 1 }}>
        <SmartNode value={c.footer.banner.icon} />
        <SmartNode
          value={c.footer.banner.title}
          typographyProps={{ variant: 'h1', sx: { ml: 1 } }}
        />
      </Stack>
      <SmartNode
        value={c.footer.banner.text}
        typographyProps={{ variant: 'body' }}
      />
      <SmartNode
        value={c.footer.banner.actions}
        component={Box}
        sx={{ mt: 1 }}
      />
    </Stack>
  );
};

export default FooterBanner;
